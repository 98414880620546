import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Images
import { CanadaFlag, LogOutIcon, LogoSvg, ProfileLock, ProfileUserLogo, StartNewChat } from '../../jsx/assets/images';

// Component
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../api/adapters/auth';
import { userProfile } from '../../api/adapters/myAccount';
import { UserDp } from '../../jsx/assets/images';
import { updateUser } from '../../redux/actions/user';
import { handleApiError, handleQueryError } from '../../utils/helpers/common.helpers';
import { clearCookies } from '../../utils/helpers/cookies.helpers';
import { notify } from '../../utils/helpers/notification.helpers';
import Button from '../generic/Button';
import Dropdown from '../menu/Dropdown';
import HeaderDropdown from '../menu/HeaderDropdown';
import Modal from '../modal/Modal';


const AuthHeader = ({ showButton }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState({})

    const user = useSelector((state) => state.user);

    const {
        data: userProfileDetails,
        refetch: refetchUserData,
        error: userDataError,
    } = useQuery({
        queryKey: ['user-profile-details', user],
        queryFn: () => userProfile().then((res) => res.data),
        // enabled: false,
    });

    useEffect(() => {
        if (userDataError) handleQueryError(userDataError, navigate);
    }, [userDataError]);

    useEffect(() => {
        if (userProfileDetails) {

            const user = {
                id: userProfileDetails._id,
                userName: userProfileDetails.oName,
                email: userProfileDetails.sEmail,
                profilePicUrl: userProfileDetails.sProfilePicUrl,
                isActive: userProfileDetails.isUserActive,
            }
            dispatch(updateUser(user));
        }
    }, [userProfileDetails])
    const queryClient = useQueryClient();

    const { mutate: mutateLogout } = useMutation({
        mutationFn: () => logout(),
        onSuccess: (response) => {
            notify('success', response?.message)
            clearCookies();
            queryClient.removeQueries('threads');
        },
        onError: (error) => handleApiError(error, navigate, setErrorMessage),
        onSettled: () => {
            dispatch(updateUser({
                id: '',
                email: '',
                profilePicUrl: '',
                userName: '',
                isActive: '',
            }));

            navigate('/sign-in');
        }
    })

    const countries = [
        { name: 'Canadian Tax' },
        { name: 'ASPE' },
        // Add more countries as needed
    ];
    const profile = [
        { name: 'Profile', img: ProfileUserLogo, linkTo: '/profile' },
        { name: 'Change Password', img: ProfileLock, linkTo: '/change-password', },
        {
            name: 'Logout', img: LogOutIcon, onClick: () => {

                setIsModalOpen(true)
            }
        },

    ];

    const defaultCountry = countries[0];
    const defaultProfile = profile[0];

    return (
        <header className=' bg-white py-3 px-12 flex items-center justify-between border-b border-gray-20 sm:px-4 relative z-10'>
            <Link to='/chat-screen'>
                <img src={LogoSvg} alt="" className=' w-40 sm:w-24' />
            </Link>
            <div className="flex items-center gap-3 sm:gap-1 ">
                {showButton && (
                    <Link to="/chat-screen">
                        <Button
                            label='New Chat'
                            icon={StartNewChat}
                            className="px-6 py-3 sm:hidden"
                        />
                    </Link>
                )}

                <Dropdown
                    dropdowndata={countries}
                    defaultData={defaultCountry}
                    dataimage={CanadaFlag}
                    dataname="Canadian Tax"
                />
                <HeaderDropdown
                    dropdowndata={profile}
                    defaultData={defaultProfile}
                    border={'border-none'}
                    customImageClass=" sm:w-6 "
                    dropdownClass=" !py-0"
                    dataImage={user?.profilePicUrl ? user?.profilePicUrl + `?${new Date().getTime()}` : UserDp}

                />
            </div>
            <Modal
                showImage={true}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                dialogImage={LogOutIcon}
                dialogTitle="Are you sure you want to log out?"
                buttons={[
                    {
                        label: 'No',
                        onClick: () => setIsModalOpen(false),
                        className: "bg-white text-black hover:text-white w-24",
                    },
                    {
                        label: 'Yes',
                        onClick: () => {
                            setIsModalOpen(false);
                            mutateLogout();
                        },
                        className: "w-24",
                    },
                ]}
            />
        </header >
    );
};

export default AuthHeader;

