import aiMlApiClient from '../client/ai/ai-ml-api-client';
import apiClient from '../client/api-client';

export const askQuestion = async (data) => {
    const url = '/ask';

    return aiMlApiClient({ url, method: 'POST', data });
};

export const getThreads = async (query) => {
    const url = `/all-threads?page=${query?.page}&per_page=${query?.perPageLimit}`;
    return aiMlApiClient({ url, method: 'GET' });
};

export const getQuestionAnswer = async (data, query, thread_id) => {
    // console.log(data);
    const url = `/thread?page=${query?.page}&per_page=${query?.perPageLimit}&thread_id=${data?.thread_id}`;
    return aiMlApiClient({ url, method: 'GET' });
};

export const deleteThread = async (id) => {
    const url = `/delete-thread?thread_id=${id}`;
    return aiMlApiClient({ url, method: 'DELETE' });
};

export const sendFeedback = async (data) => {
    const url = '/user/feedback/add';
    return apiClient({ url, method: 'POST', data });
};

export const searchThreads = async (data) => {
    const url = '/search-thread-by-keywords';
    return aiMlApiClient({ url, method: 'POST', data });
};
