import React, { Fragment, useEffect, useRef, useState } from 'react';

// Components
import ChatBox from '../../../components/chat-components/ChatBox';
import ChatHistoryList from '../../../components/chat-components/ChatHistory';
import Button from '../../../components/generic/Button';

import WelcomeMessage from '../../../components/chat-components/WelcomeMessage';

// Images
import {
    ChatbotHistory,
    ChatbotPlus,
    ChatbotSearch,
    ChatbotSend,
    DropdownArrow,
    StartNewChat,
} from '../../assets/images';

import { Menu, Transition } from '@headlessui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { askQuestion, getThreads, searchThreads } from '../../../api/adapters/chat';
import { handleApiError } from '../../../utils/helpers/common.helpers';

const ChatScreen = () => {
    // States
    const [selectedChat, setSelectedChat] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [selectedChatIndex, setSelectedChatIndex] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chatToDelete, setChatToDelete] = useState(null);

    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([]);
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [messages, setMessages] = useState([]);
    const [threadId, setThreadId] = useState('');
    const [searchThread, setSearchThread] = useState('')
    const user = useSelector((state) => state.user);
    const [feedbackSend, setFeedbackSend] = useState(false);
    const startNewChat = () => {
        const newChat = 'New Chat';
        // setChatHistory(null);
        setMessages('');
        setThreadId('');
        // setChatHistory((prevHistory) => [...prevHistory, newChat]);
        setSelectedChat(newChat);
    };

    const [query, setQuery] = useState({
        page: 1,
        perPageLimit: 10,
    });

    const [chatMessageQuery, setChatMessageQuery] = useState({
        page: 1,
        perPageLimit: 3,
    });

    const { data: getAllThreads, refetch: refetchThreads } = useQuery({
        queryKey: ['threads', query, answers, searchThread],
        queryFn: () => getThreads(query),
        enabled: !searchThread,
    });

    const { mutate: mutateSearch, isLoading: isSearchLoading } = useMutation({
        mutationFn: (data) => searchThreads(data),
        onSuccess: (response) => {
            setChatHistory(response.data);
        },
        onError: (error) => handleApiError(error, navigate, setFormErrors),
    })

    const handleSearchThread = async (e) => {
        setSearchThread(e.target.value)
        if (e.target.value.length >= 3) {
            mutateSearch({ keywords: e.target.value })
            return;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await refetchThreads();
            if (response !== '') {
                const newThreads = Array.isArray(response?.data?.data?.threads)
                    ? response?.data?.data?.threads
                    : [];
                // setChatHistory((prevChatHistory) => [...newThreads, ...prevChatHistory]);
                setChatHistory((prevChatHistory) => {
                    const uniqueNewThreads = newThreads.filter(
                        (newThread) =>
                            !prevChatHistory.some((prevThread) => prevThread.id === newThread.id)
                    );
                    return [...uniqueNewThreads, ...prevChatHistory];
                });
            }
        };
        fetchData();
    }, [query]);

    useEffect(() => {
        if (getAllThreads && !searchThread) {
            const newThreads = Array.isArray(getAllThreads?.data?.threads)
                ? getAllThreads?.data?.threads
                : [];

            setChatHistory((prevChatHistory) => {
                const uniqueNewThreads = newThreads.filter(
                    (newThread) =>
                        !prevChatHistory?.some((prevThread) => prevThread.id === newThread.id)
                );
                return [...uniqueNewThreads, ...prevChatHistory];
            });
        }
    }, [getAllThreads, answers, searchThread]);


    useEffect(() => {

        if (getAllThreads?.data?.threads?.length)
            setChatHistory(getAllThreads?.data?.threads)
    }, [getAllThreads])


    const {
        mutate: mutateAskQuestion,
    } = useMutation({
        mutationFn: (data) => askQuestion(data),
        onMutate: () => {
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: 'Typing' },
            ]);
        },
        onSuccess: (response) => {
            // refetchThreads();
            setAnswers(response.data.answer);
            setMessages((prevMessages) => [
                ...prevMessages.filter((message) => message.sender !== 'Typing'),
                { sender: 'Bot', content: response.data.answer, iHistory: response.data.converstaion_id },
            ]);
            setThreadId(response.data.thread_id);
        },
        onError: (error) => {
            handleApiError(error, navigate, setFormErrors)
            setMessages((prevMessages) => [
                ...prevMessages.filter((message) => message.sender !== 'Typing'),
            ]);
        },
    });

    const handleInputChange = (e) => {
        setQuestion(e.target.value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const oData = {
            question: question,
            country_name: 'canada',
            is_aspe_rules_query: user?.isCanada,
            thread_id: threadId ? threadId : null,
        };
        if (question.length > 0) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: 'User', content: question },
            ]);
            mutateAskQuestion(oData);
        }
        setQuestion('');
    };
    const chatContainerRef = useRef(null);
    const handleInputFocus = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    return (
        <div className='flex gap-3.5 h-full sm:flex-col'>
            <Button
                icon={StartNewChat}
                className='hidden sm:block fixed right-4 bottom-12 z-10 !p-3.5'
                onClick={startNewChat}
            />
            {/* Left Section */}
            <div className=' sm:hidden basis-3/12 min-w-72 bg-white  p-3.5 rounded-lg h-full overflow-y-auto'>
                <div>
                    <div className='flex items-center gap-1 mb-6'>
                        <img
                            src={ChatbotHistory}
                            alt=''
                            className=' w-5 aspect-square '
                        />
                        <p className='p-medium text-primary font-medium'>
                            History
                        </p>
                    </div>
                    <div>
                        <Button
                            // type='submit'
                            className='w-full flex-row-reverse justify-between '
                            onClick={startNewChat}
                            icon={ChatbotPlus}
                            label='New Chat'
                            size='large'
                        />
                    </div>
                    <div className='relative'>
                        <input
                            type='text'
                            className='w-full p-3 pr-12 bg-gray-40 mt-3.5 mb-2.5 rounded-lg focus:outline-none'
                            placeholder='Search something'
                            // value={searchThread}
                            onChange={handleSearchThread}
                        />
                        <button className='absolute right-4 top-1/2 -translate-y-1/2'>
                            <img src={ChatbotSearch} alt='' />
                        </button>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <ChatHistoryList
                            chatHistory={chatHistory}
                            setSelectedChat={setSelectedChat}
                            setSelectedChatIndex={setSelectedChatIndex}
                            selectedChatIndex={selectedChatIndex}
                            setIsModalOpen={setIsModalOpen}
                            setChatToDelete={setChatToDelete}
                            isModalOpen={isModalOpen}
                            setMessages={setMessages}
                            setThreadId={setThreadId}
                            threadId={threadId}
                            refetchThreads={refetchThreads}
                            setChatMessageQuery={setChatMessageQuery}
                            chatMessageQuery={chatMessageQuery}
                            setQuery={setQuery}
                            query={query}
                            setChatHistory={setChatHistory}
                            feedbackSend={feedbackSend}
                        />
                    </div>
                </div>
            </div>
            <div className='hidden sm:block'>
                <Menu as='div' className='relative'>
                    {({ open }) => (
                        <>
                            <div>
                                {open && (
                                    <div
                                        className={`fixed inset-0  bg-gray-500 bg-opacity-50 transition-all`}
                                        aria-hidden='true'
                                    ></div>
                                )}
                                <Menu.Button className='relative z-1 bg-white w-full flex items-center justify-between p-3.5 rounded-md '>
                                    <div className='flex items-center gap-1  '>
                                        <img
                                            src={ChatbotHistory}
                                            alt=''
                                            className=' w-5 aspect-square '
                                        />
                                        <p className='p-medium text-primary font-medium'>
                                            History
                                        </p>
                                    </div>
                                    <div>
                                        <img
                                            src={DropdownArrow}
                                            alt=''
                                            className={`${open
                                                ? 'rotate-180 transform'
                                                : ''
                                                }  h-2  w-1.5 text-purple-500`}
                                        />
                                    </div>
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                show={open}
                                enter='transition ease-out duration-100'
                                enterFrom='transform opacity-0 scale-95'
                                enterTo='transform opacity-100 scale-100'
                                leave='transition ease-in duration-75'
                                leaveFrom='transform opacity-100 scale-100'
                                leaveTo='transform opacity-0 scale-95'
                            >
                                <Menu.Items className='absolute right-0 w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10'>
                                    <div className=' w-full bg-white  p-3.5 rounded-lg  overflow-y-auto '>
                                        <div>
                                            <div className='relative'>
                                                <input
                                                    type='text'
                                                    className='w-full p-3 pr-12 bg-gray-40 mt-3.5 mb-2.5 rounded-lg focus:outline-none'
                                                    placeholder='Search something'
                                                />
                                                <button className='absolute right-4 top-1/2 -translate-y-1/2'>
                                                    <img
                                                        src={ChatbotSearch}
                                                        alt=''
                                                    />
                                                </button>
                                            </div>
                                            <div className='flex flex-col gap-3'>
                                                <ChatHistoryList
                                                    chatHistory={chatHistory}
                                                    setSelectedChat={
                                                        setSelectedChat
                                                    }
                                                    setSelectedChatIndex={
                                                        setSelectedChatIndex
                                                    }
                                                    selectedChatIndex={
                                                        selectedChatIndex
                                                    }
                                                    setIsModalOpen={
                                                        setIsModalOpen
                                                    }
                                                    setChatToDelete={
                                                        setChatToDelete
                                                    }
                                                    isModalOpen={isModalOpen}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </>
                    )}
                </Menu>
            </div>
            {/* Left Section Ends */}
            <div className=' basis-9/12 bg-white h-full overflow-y-auto rounded-lg flex-1'>
                <div className=' h-full flex flex-col justify-between'>
                    <div className='flex-1 overflow-hidden' >
                        {messages && chatHistory?.length >= 0 ? (
                            <ChatBox
                                messages={messages}
                                question={question}
                                threadId={threadId}
                                setChatMessageQuery={setChatMessageQuery}
                                chatMessageQuery={chatMessageQuery}
                                setFeedbackSend={setFeedbackSend}
                                setMessages={setMessages}
                                chatContainerRef={chatContainerRef}
                            />
                        ) : (
                            <WelcomeMessage />
                        )}
                    </div>
                    <div className='border-t border-gray-90 pt-5 px-10 pb-4 sm:py-3.5 sm:px-2.5 '>
                        <form action=''>
                            <div className='flex items-center gap-2'>
                                <input
                                    type='text'
                                    name='content'
                                    id=''
                                    value={question}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    placeholder='Write a message here..'
                                    className=' text-tiny py-4 px-3 border-2 border-transparent bg-gray-40 flex-1 focus:outline-none rounded-lg focus:border-gray-50 focus:bg-white focus:text-black focus:font-medium sm:p-3'
                                    required
                                />

                                <button
                                    className=' bg-secondary p-3 rounded-lg'
                                    type='submit'
                                    onClick={handleSubmit}
                                >
                                    <img
                                        src={ChatbotSend}
                                        alt=''
                                        className=' sm:w-5 aspect-square'
                                    />
                                </button>
                            </div>
                        </form>
                        <p className='text-micro text-center mt-2.5'>
                            AI Chatbot can make mistakes. Consider checking
                            important information.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatScreen;
