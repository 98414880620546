import PropTypes from 'prop-types';
import React from 'react';

const InputField = (props) => {
    const {
        label,
        type,
        name,
        placeholder,
        isDisabled,
        errorMessage,
        onChange,
        icon,
        value,
        className,
        formGroupClass, onBlur, children
    } = props;



    return (
        <div className={`form-group mt-6 ${formGroupClass}`}>
            <label className='block p-small text-gray-60 mb-2'>{label}</label>
            <div className='relative'>
                <input
                    className={`form-control peer ${value ? 'active' : ''} ${className}`}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    value={value}
                    onChange={(e) => onChange(e)}
                    onBlur={onBlur}
                />
                {/* Password Icon */}
                {children}
                <div className='absolute top-3 left-3.5 peer-focus:brightness-0 peer-[.active]:brightness-0'>
                    {icon && <img src={icon} alt={label} />}
                </div>
            </div>
            {errorMessage && (
                <label className='text-danger small' style={{ color: '#f21c1c' }}>{errorMessage}</label>
            )}
        </div>
    );
};

InputField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string
};

export default InputField;
