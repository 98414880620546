import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components...
import ServiceCard from '../../../components/card/ServiceCard';
import FaqAccordion from '../../../components/faq-accordion/FaqAccordion';
import HomeBanner from '../../../components/home-banner/HomeBanner';
import TestimonyCard from '../../../components/testimonial-card/TestimonyCard';
import { formatPageTitle } from '../../../utils/helpers/common.helpers';

// Images...
import {
    AllDayAssistance,
    ChatbotExperience,
    DirectFeedback,
    DotImage,
    RecommendationsImage,
    SecureDataImage,
    SimplifiedMethod,
    TaxFillingImage,
    UserImageFive,
    UserImageFour,
    UserImageOne,
    UserImageSix,
    UserImageThree,
    UserImageTwo,
} from '../../assets/images';

const Home = () => {
    const user = useSelector((state) => state.user);

    /**
     * Sets the document title to 'Home'.
     */
    useEffect(() => {
        document.title = formatPageTitle('Home');
    }, []);

    // Service Section Card Data....
    const cardData = [
        {
            cardTitle: 'Smart Tax Filing Assist',
            cardDescription:
                'TaxPay AI intelligently guides users through the tax filing process, ensuring accuracy.',
            cardImg: TaxFillingImage,
        },
        {
            cardTitle: 'Own Recommendations',
            cardDescription:
                'Tailored suggestions based on user inputs and past filing history help optimize deductions and maximize refunds.',
            cardImg: RecommendationsImage,
        },
        {
            cardTitle: 'Secure Data',
            cardDescription:
                'Robust encryption and advanced security measures safeguard sensitive financial information.',
            cardImg: SecureDataImage,
        },
    ];

    // Feature Section Data....
    const featureData = [
        {
            featureImage: SimplifiedMethod,
            featureTitle: 'Simplified Method for Querying.',
            featureDescription:
                'Simplify your inquiries with our user-friendly questioning system. Our platform offers an intuitive experience for all users, ensuring easy access to the information you need. Say goodbye to complexity and hello to simplicity with our streamlined process.',
        },
        {
            featureImage: DirectFeedback,
            featureTitle: 'Provide Direct Feedback on the Output.',
            featureDescription:
                'You can share your feedback directly on the output. We value your input and encourage you to provide your thoughts on the content. Your feedback helps us improve and tailor our offerings to better meet your needs.',
        },
        {
            featureImage: AllDayAssistance,
            featureTitle: '24/7 Assistance: Always Here to Help',
            featureDescription:
                " We're available around the clock to provide assistance whenever you need it. Our support is accessible 24/7 to ensure you receive timely help and guidance whenever you require it.",
        },
    ];

    // Testimonies ....
    const testimonies = [
        {
            userImage: UserImageOne,
            userName: 'Ben Ophoven-Baldwin',
            userFeedback:
                'We evaluated a handful of no-code tools but found them to be too inflexible and brittle for our needs. Dopt was the only product we found that gave us complete control over the product experience while still helping us ship and iterate as fast as we could with a no code tool.',
            userRating: 4,
            userDesignation: 'Sr. Growth PM, Superhuman',
        },
        {
            userImage: UserImageTwo,
            userName: 'Hubert Palan',
            userFeedback:
                "Our customer’s reaction to our Dock space: ‘Origin came through with the best onboarding resources I've ever seen. ",
            userRating: 4,
            userDesignation: 'CEO, Productboard',
        },
        {
            userImage: UserImageThree,
            userName: 'Bragi Bergþórsson',
            userFeedback:
                'Before Dopt, we were using two different no-code tools for our in-product onboarding. They were rigid, difficult to update, and inconsistent with our product’s design (which we pour so much attention into). With Dopt, we consolidated all the complex onboarding logic into one platform with the freedom to build the experience exactly as we envisioned.',
            userRating: 3,
            userDesignation: 'Product Engineer, Avo',
        },
        {
            userImage: UserImageFour,
            userName: 'Indy Khare',
            userFeedback:
                'Self-serve is a core part of our product strategy and Dopt has enabled our teams to ship personalized, native onboarding in weeks instead of the months we estimated it would take if we built in-house.',
            userRating: 2,
            userDesignation: 'Head of Engineering, Convex',
        },
        {
            userImage: UserImageFive,
            userName: 'Alex Vale',
            userFeedback:
                'Before Dopt, we were using two different no-code tools for our in-product onboarding. They were rigid, difficult to update, and inconsistent with our product’s design (which we pour so much attention into). With Dopt, we consolidated all the complex onboarding logic into one platform with the freedom to build the experience exactly as we envisioned',
            userRating: 5,
            userDesignation: 'Growth, Attio',
        },
        {
            userImage: UserImageSix,
            userName: 'Kristie Howard',
            userFeedback:
                'Dopt helps both our growth and core product development teams build and iterate on personalized onboarding, education, upsell, and announcement experiences that meet our high-quality bar with far less time spent.',
            userRating: 5,
            userDesignation: 'CPO, Conductor',
        },
    ];

    return (
        <>
            <HomeBanner />
            <div className='bg-gray-10 mt-8 py-9 rounded-2.5xl'>
                <div className='container flex gap-8 md:flex-col'>
                    <div className='basis-5/12 md:basis-full'>
                        <img
                            src={ChatbotExperience}
                            alt='ChatbotExperience'
                            className='md:mx-auto'
                        />
                    </div>
                    <div className='basis-7/12 md:basis-full'>
                        <h2 className='heading-3 mt-10 md:mt-0'>
                            Transform Customer Support AI Chatbot Experience.
                        </h2>
                        <p className='p-large mt-3'>
                            Introducing TaxPay AI, the cutting-edge solution
                            revolutionizing tax management. Seamlessly navigate
                            the complexities of taxes with our Interactive AI
                            Chatbot, designed to streamline your experience and
                            provide real-time support. Say goodbye to cumbersome
                            processes and hello to efficiency.
                        </p>
                    </div>
                </div>
            </div>
            {/* Service Section */}
            <div className='section-spacing-top'>
                <div className='container'>
                    <div className='flex items-center gap-2'>
                        <img src={DotImage} alt='' />
                        <p className='text-base text-primary font-semibold'>
                            Provided to Your Needs
                        </p>
                    </div>
                    <h2 className='heading-2 heading-margin'>
                        Services provided to your needs
                    </h2>
                    {/* Service Section Card*/}
                    <div className=' flex gap-6 sm:flex-col'>
                        {cardData.map((card, index) => (
                            <ServiceCard
                                key={index}
                                title={card.cardTitle}
                                description={card.cardDescription}
                                cardImage={card.cardImg}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {/* Service Section Ends */}

            {/* Feature Section */}
            <div className='section-spacing-top'>
                <div className='container'>
                    <div className='flex items-center gap-2'>
                        <img src={DotImage} alt='' />
                        <p className='text-base text-primary font-semibold'>
                            Functionality For You
                        </p>
                    </div>
                    <h2 className='heading-2 heading-margin'>
                        Features you like it which work made easier
                    </h2>
                    <div>
                        {featureData.map((feature, index) => (
                            <div
                                className='flex gap-6 first:mt-0 section-spacing-top-small sm:flex-col'
                                key={index}
                            >
                                <div className='basis-6/12'>
                                    <img src={feature.featureImage} alt='' />
                                </div>
                                <div className='basis-6/12'>
                                    <h5 className='heading-5 mt-8 mb-2.5 md:mt-0'>
                                        {feature.featureTitle}
                                    </h5>
                                    <p className='p-large '>
                                        {feature.featureDescription}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Features Section Ends */}

            {/* Testimonial Section */}
            <div className=' bg-gray-40 -mx-3.5 section-inner-space section-spacing-top relative after:block after:absolute after:w-full after:h-1/3 after:bg-testimoniesBg after:left-0 after:bottom-0'>
                <div className='container'>
                    <div className='flex items-center gap-2'>
                        <img src={DotImage} alt='' />
                        <p className='text-base text-primary font-semibold'>
                            Testimonial
                        </p>
                    </div>
                    <h2 className='heading-2 heading-margin'>
                        What our customer says
                    </h2>
                    <div className=' columns-3 md:columns-2 gap-6 sm:columns-1'>
                        {testimonies.map((testimony, index) => (
                            <TestimonyCard
                                key={index}
                                userImage={testimony.userImage}
                                userRating={testimony.userRating}
                                userName={testimony.userName}
                                userFeedback={testimony.userFeedback}
                                userDesignation={testimony.userDesignation}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* Faq Section */}
            <div className='section-spacing-top'>
                <div className='container'>
                    <FaqAccordion />
                </div>
            </div>
        </>
    );
};

export default Home;
