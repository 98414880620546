import apiClient from '../client/api-client';

export const userProfile = async () => {
    const url = 'user/profile';
    return apiClient({ url, method: 'GET' });
};

export const userEditProfile = async (data) => {
    const url = 'user/profile/update';
    return apiClient({ url, method: 'PATCH', data });
};

export const changePassword = async (data) => {
    const url = 'user/password/update';
    return apiClient({ url, method: 'PATCH', data });
};
