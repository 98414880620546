import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthComponentWrapper from '../Wrappers/AuthComponentWrapper';
import MainComponentWrapper from '../Wrappers/MainComponentWrapper';
import ForgotPassword from '../jsx/pages/auth/ForgotPassword';
import ResetPassword from '../jsx/pages/auth/ResetPassword';
import SignIn from '../jsx/pages/auth/SignIn';
import SignUp from '../jsx/pages/auth/SignUp';

import ProtectedComponentWrapper from '../Wrappers/ProtectedComponentWrapper';
import ChatScreen from '../jsx/pages/chat/ChatScreen';
import WelcomeScreen from '../jsx/pages/chat/WelcomeScreen';
import PageNotFound from '../jsx/pages/errors/PageNotFound';
import Home from '../jsx/pages/home/Home';
import PrivacyPolicy from '../jsx/pages/privacy-policy/PrivacyPolicy';
import ChangePassword from '../jsx/pages/profile/ChangePassword';
import EditProfile from '../jsx/pages/profile/EditProfile';
import MyProfile from '../jsx/pages/profile/MyProfile';
import TermsAndConditions from '../jsx/pages/terms-and-conditions/TermsAndConditions';
import WhoWeAre from '../jsx/pages/who-we-are/WhoWeAre';

const ProjectRoutes = () => {
    return (
        <Routes>
            {/* ****************************** */}
            {/* *** Open Routes - Non-Auth *** */}
            {/* ****************************** */}
            <Route
                path='/sign-in'
                element={<AuthComponentWrapper component={<SignIn />} />}
            />
            <Route
                path='/sign-up'
                element={<AuthComponentWrapper component={<SignUp />} />}
            />
            <Route
                path='/forgot-password'
                element={<AuthComponentWrapper component={<ForgotPassword />} />}
            />
            <Route
                path='/reset-password/:token'
                element={<AuthComponentWrapper component={<ResetPassword />} />}
            />
            {/*  */}
            <Route
                path='/welcome-screen'
                element={<ProtectedComponentWrapper component={<WelcomeScreen />} />}
            />
            <Route
                path='/chat-screen'
                element={<ProtectedComponentWrapper component={<ChatScreen />} />}
            />
            {/* Profile */}
            <Route
                path='/profile'
                element={<ProtectedComponentWrapper component={<MyProfile />} />}
            />
            <Route
                path='/edit-profile'
                element={<ProtectedComponentWrapper component={<EditProfile />} />}
            />
            <Route
                path='/change-password'
                element={<ProtectedComponentWrapper component={<ChangePassword />} />}
            />

            {/* ***************************************** */}
            {/* *** Restricted Routes - Auth Required *** */}
            {/* ***************************************** */}
            <Route
                path='/'
                element={<MainComponentWrapper component={<Home />} />}
            />
            <Route
                path='/who-we-are'
                element={<MainComponentWrapper component={<WhoWeAre />} />}
            />
            <Route
                path='/privacy-policy'
                element={<MainComponentWrapper component={<PrivacyPolicy />} />}
            />
            <Route
                path='/terms-and-conditions'
                element={
                    <MainComponentWrapper component={<TermsAndConditions />} />
                }
            />
            {/* <Route
                path='/'
                element={<ProtectedComponentWrapper component={<Home />} />}
            /> */}

            {/* ************************************ */}
            {/* *** Public Routes - Error Routes *** */}
            {/* ************************************ */}
            <Route path='/404' element={<PageNotFound />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    );
};

export default ProjectRoutes;
