/**
 * @summary This is the root reducer for the Redux store.
 *
 * This module exports the root reducer for the Redux store.
 * More reducers can be added here by importing and combining them using `combineReducers`.
 */

import { combineReducers } from '@reduxjs/toolkit';

import user from './user';

const rootReducer = combineReducers({ user });

export default rootReducer;
