import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * @function
 * Sets a cookie with the specified key and value.
 *
 * @param {string} key - The name of the cookie to set.
 * @param {string | number} value - The value of the cookie.
 *
 * @example
 * setCookie('foo', 'bar');
 */
export const setCookie = (key, value) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);

    cookies.set(key, value, { expires });
};

/**
 * @function
 * Retrieve the value of a cookie by its key.
 *
 * @param {string} key - The name of the cookie to retrieve.
 * @returns {string | null} - The value of the cookie or null if it does not exist.
 *
 * @example
 * getCookie('foo');  // Returns 'bar' if set
 */
export const getCookie = (key) => cookies.get(key);

/**
 * @function
 * Remove a cookie by its key.
 *
 * @param {string} key - The name of the cookie to remove.
 *
 * @example
 * removeCookie('foo');
 */
export const removeCookie = (key) => cookies.remove(key);

/**
 * @function
 * Retrieve the current user's user ID from a cookie.
 *
 * @returns {string | null} - The user ID or null if it does not exist.
 *
 * @example
 * getUserId();
 */
export const getUserId = () => getCookie('__token');

/**
 * @function
 * Set the user ID in a cookie.
 *
 * @param {string} userId - The user ID to set.
 *
 * @example
 * setUserId('fooID');
 */
export const setUserId = (userId) => setCookie('__userId', userId);

/**
 * @function
 * Retrieve the user's token from a cookie, removing any '%20' padding.
 *
 * @returns {string | null} - The user's token or null if it does not exist.
 *
 * @example
 * getToken();
 */
export const getToken = () => getCookie('__token')?.replaceAll('%20', '');

/**
 * @function
 * Set the user's token in a cookie, padding it with '%20'.
 *
 * @param {string} token - The user's token to set.
 *
 * @example
 * setToken('fooToken');
 */
export const setToken = (token) => setCookie('__token', `%20${token}%20`);

/**
 * @function
 * Clear all cookies.
 *
 * @example
 * clearCookies();
 */
export const clearCookies = () => {
    const allCookies = cookies.getAll();

    for (const cookie in allCookies) {
        removeCookie(cookie);
    }
};
