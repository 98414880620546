/**
 * @function
 * Example adapter function for signing in a user.
 * Note: This function can be removed or updated as needed for the specific use case.
 *
 * @param {string} language - The language code for the request header.
 * @param {Object} data - The data object containing user credentials.
 * @returns {Promise} - A promise that resolves to the API response.
 */
import apiClient from '../client/api-client';

export const signIn = async (data) => {
    const url = 'user/auth/login';
    return apiClient({ url, data, method: 'POST' });
};

export const signUp = async (data) => {
    const url = 'user/auth/register';
    return apiClient({ url, data, method: 'POST' });
};

export const logout = async () => {
    const url = 'user/auth/logout';
    return apiClient({ url, method: 'POST' });
};

export const forgotPassword = async (data) => {
    const url = 'user/auth/password/reset';
    return apiClient({ url, data, method: 'POST' });
};

export const verifyResetPasswordToken = async (token) => {
    const url = `user/auth/${token}/reset`;
    return apiClient({ url, method: 'GET' });
};

export const resetPassword = async (data, token) => {
    const url = `user/auth/${token}/reset`;
    return apiClient({ url, data, method: 'POST' });
};
