import React from 'react';

const ServiceCard = (props) => {
    const { cardImage, title, description } = props;
    return (
        // Service Card
        <div className=' basis-4/12 border-gray-50 border rounded-2.7xl p-7 md:p-5'>
            <img
                src={cardImage}
                alt='Service Image'
                className=' w-9 aspect-square'
            />
            <h2 className='heading-6 mt-5 mb-2 md:mt-3'>{title}</h2>
            <p className='p-large'>{description}</p>
        </div>
    );
};

export default ServiceCard;
