import React from 'react';
import { useSelector } from 'react-redux';

const WelcomeMessage = () => {
    const user = useSelector((state) => state.user);

    return (
        <div className=' max-w-xl mt-20 text-center mx-auto md:px-5 sm:mt-10'>
            <div className="heading-6 font-light text-gray flex justify-center items-center gap-2.5 sm:gap-1 ">
                Welcome!
                <span className='heading-lg'>{user?.userName}</span>
            </div>
            <div className='p-large font-medium text-black mt-5 mb-2.5 md:mt-4 md:mb-2 sm:mt-4 sm:mb-4'>
                How can I help you?
            </div>
            <p className='text-gray-60 font-normal p-small'>
                Welcome to our Tax Planning Systems! Streamline your finances and maximize returns with our AI-powered solutions. Let's optimize your tax strategy together!
            </p>
        </div>
    );
};

export default WelcomeMessage;
