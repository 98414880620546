import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../../api/adapters/auth';
import InputField from '../../../components/form-elements/InputField';
import Button from '../../../components/generic/Button';
import { formatPageTitle, handleApiError } from '../../../utils/helpers/common.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import { EmailIcon, ForgotPasswordBg } from '../../assets/images';
import AuthLayout from './AuthLayout';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputValues, setInputValues] = useState({ email: '', });
    const [formErrors, setFormErrors] = useState({
        sEmail: '',
    });

    const { mutate: mutateForgotPassword, isPending: isForgotPasswordLoading } = useMutation({
        mutationFn: (data) => forgotPassword(data),
        onSuccess: (response) => {
            notify('success', response?.message)
            setInputValues({ email: '' });
            navigate('/sign-in');
        },
        onError: (error) => handleApiError(error, navigate, setFormErrors),

    })

    /**
     * Sets the document title to 'Sign In'.
     */
    useEffect(() => {
        document.title = formatPageTitle('Forgot Password');
    }, []);

    const handleInputChange = (e) => {
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (checkValidation()) mutateForgotPassword({ sEmail: inputValues?.email })
    }

    const checkValidation = () => {
        const errors = formErrors;

        if (!inputValues.email.trim()) {
            errors.sEmail = 'Please enter your email';
        } else {
            errors.sEmail = '';
        }


        setFormErrors({ ...errors });
        if (errors.sEmail)
            return false;
        else return true;
    }


    return (
        <div>
            <AuthLayout
                leftImage={ForgotPasswordBg}
                title='Forgot Password'
                description='Enter your register Email Id'
                showBackButton={true}
            >
                <form className=''>
                    <InputField
                        label='Email'
                        type='email'
                        name='email'
                        placeholder='Enter Email Id'
                        value={inputValues?.email}
                        onBlur={formErrors.sEmail ? checkValidation : null}
                        onChange={handleInputChange}
                        icon={EmailIcon}
                        errorMessage={formErrors.sEmail}

                    />
                    <Button
                        type='submit'
                        className='w-full mt-10'
                        onClick={handleSubmit}
                        label='Submit'
                        size='large'
                        loader={isForgotPasswordLoading}
                    />
                    {/* <Link to="" className='p-small underline text-black mt-6 flex justify-center font-medium hover:text-primary duration-200 ease-in-out'>Resend Link</Link> */}
                </form>
            </AuthLayout>
        </div>
    );
};

export default ForgotPassword;
