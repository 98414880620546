import { notify } from './notification.helpers';

/**
 * @function
 * Formats the given title with a specific structure.
 *
 * This function takes a string title and appends a specific brand suffix, '| Interactive AI Chatbot', to it.
 * It's primarily used for setting the title of web pages consistently across an application.
 *
 * @param {string} title - The initial title that needs formatting.
 * @returns {string} The formatted title, which includes the brand suffix.
 *
 * @example
 *  const pageTitle = formatPageTitle('Home');
 *  console.log(pageTitle); // Output: 'Home | Interactive AI Chatbot'
 */
export const formatPageTitle = (title) => `${title} | Interactive AI Chatbot`;

/**
 * @function
 * Handles errors returned by an API call.
 *
 * This function differentiates between validation and unexpected errors,
 * performing different actions for each. For 422 HTTP errors (Unprocessable Entity),
 * it creates an object with all the errors returned from the response and passes it to a form error setter.
 * For all other errors, it triggers a notification with the error message or a default error message.
 *
 * @param {object} error - The error object caught from an API request, usually from a catch block.
 * @param {function} t - The translation function, used for localizing the default error message.
 * @param {function} formErrorSetter - Optional function that sets form errors. It gets an object where keys are the fields that failed validation, and values are the corresponding error messages.
 *
 * @example
 *  handleApiError(apiError, translate, setFormErrors);
 */
export const handleApiError = (
    error,
    t,
    navigate,
    formErrorSetter = () => {}
) => {
    if (error.status === 422) {
        const errObj = {};
        error.message?.response?.data?.data?.errors?.forEach((err) => {
            errObj[err.path] = err.msg;
        });

        formErrorSetter(errObj);
    } else if (error.status === 401) {
        notify('error', error.message);
        navigate('/sign-in');
    } else {
        notify(
            'error',
            typeof error.message === 'string'
                ? error.message
                : 'Something went wrong.'
        );
    }
};

export const handleQueryError = (error, navigate) => {
    if (error.status === 401) {
        notify('error', error.message);
        navigate('/sign-in');
    }
};
