import React from 'react';
import PropTypes from 'prop-types';

/**
 * ErrorBoundary component is a wrapper component that catches any errors
 * thrown by its children and renders a fallback component.
 *
 * @component
 * @param {Object} props
 * @param {ReactElement} props.fallback - Fallback component to be rendered
 * @param {ReactElement} props.children - Children component to be rendered
 * @returns {ReactElement}
 */
export default class ErrorBoundary extends React.Component {
    static propTypes = {
        fallback: PropTypes.element.isRequired,
        children: PropTypes.element.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    // Use this function to send error to Error Reporting service
    // componentDidCatch(error, info) {}

    render() {
        return this.state.hasError ? this.props.fallback : this.props.children;
    }
}
