// ChatHistoryList.jsx
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteThread, getQuestionAnswer } from '../../api/adapters/chat';
import { Trash } from '../../jsx/assets/images';
import { handleApiError } from '../../utils/helpers/common.helpers';
import { notify } from '../../utils/helpers/notification.helpers';
import Modal from '../modal/Modal'; // Update with your actual import

const ChatHistoryList = (props) => {

    const { feedbackSend, setChatMessageQuery, refetchThreads, threadId, setThreadId, chatHistory, setMessages, setSelectedChat, setSelectedChatIndex, setIsModalOpen, isModalOpen, chatMessageQuery } = props
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [chatId, setChatId] = useState({ thread_id: '' })

    const { data: threadData, refetch } = useQuery({
        queryKey: ['threads', chatMessageQuery?.page, chatId, feedbackSend],
        queryFn: () => getQuestionAnswer(chatId, chatMessageQuery),

        onError: (error) => handleApiError(error, navigate, setFormErrors),


    })

    useEffect(() => {
        if (threadData) {

            const newMessages = threadData?.data?.threads.reduce((acc, thread) => {
                acc.push({ sender: 'Bot', content: thread.History?.Answer, thread_id: thread.thread_id, iHistory: thread._id, is_feedback_exists: thread.is_feedback_exists });
                acc.push({ sender: 'User', content: thread.History?.Question, thread_id: thread.thread_id, iHistory: thread._id, is_feedback_exists: thread.is_feedback_exists },);
                return acc;
            }, []);
            // setThreadId(threadData?.data?.threads[0]?.thread_id)
            // setMessages(prevMessages => [...prevMessages, ...newMessages]);
            setMessages(prevMessages => {
                const filteredNewMessages = newMessages.filter(
                    message => message.thread_id === threadData?.data?.threads[0]?.thread_id
                );
                const reversedNewMessages = filteredNewMessages.reverse();

                return [...reversedNewMessages, ...prevMessages,];
            });

        }
    }, [threadData])

    useEffect(() => {
        refetch();
    }, [chatMessageQuery, chatId.thread_id])



    const { mutate: mutateDeleteThread } = useMutation({
        mutationFn: (id) => deleteThread(id),
        onSuccess: () => {
            refetchThreads();
            setMessages('')
            setThreadId('')
            let newChat = 'New Chat'
            setSelectedChat(newChat)
            notify('success', 'Thread deleted successfully')
        },
        onError: (error) => handleApiError(error, navigate, setFormErrors),

    })

    const handleDelete = () => {

        mutateDeleteThread(threadId)
    }

    return (

        <div className='flex flex-col gap-3 overflow-y-auto ' >
            {chatHistory?.map((chat, index) => (

                <button
                    key={index}
                    onClick={() => {
                        if (chatId.thread_id !== chat?._id) {
                            // setSelectedChat(chat)
                            setSelectedChatIndex(index)

                            // mutateThreads({ thread_id: chat?._id })
                            setChatId({ thread_id: chat?._id })
                            setChatMessageQuery({ page: 1, perPageLimit: 10 })
                            setMessages([])
                            setThreadId(chat?._id)
                        }

                    }}
                    className='
            flex justify-between cursor-pointer py-4 px-3 border border-gray-90 rounded-lg focus:bg-gray-30 active:border-primary focus:border-primary focus:border-2 group overflow-hidden text-ellipsis
            '>
                    <p className='p-small text-gray-60 font-semibold group-focus:text-primary group-hover:text-black duration-200 text-left  text-nowrap text-ellipsis w-72 overflow-hidden'>
                        {chat?.thread_name}
                    </p>
                    <>
                        <button
                            className='opacity-0  hover:scale-125 duration-300 group-hover:opacity-100  group-active:opacity:100 group-focus:opacity-100 shrink-0'
                            onClick={(e) => {
                                e.preventDefault();
                                setIsModalOpen(true);
                            }}
                        >
                            <img src={Trash} alt="" />
                        </button>

                    </>

                </button>
            ))
            }
            <Modal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                dialogTitle="Are you sure you want to delete this chat?"
                dialogImage={Trash}
                buttons={[
                    {
                        label: 'No',
                        onClick: () => setIsModalOpen(false),
                        className: "bg-white text-black hover:text-white w-24",
                    },
                    {
                        label: 'Yes',
                        onClick: () => {
                            handleDelete()
                            setIsModalOpen(false);
                        },
                        className: "w-24",
                    },
                ]}
            />
        </div >

    );
}

export default ChatHistoryList;