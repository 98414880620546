import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Redux

// Helpers
import { formatPageTitle, handleApiError } from '../../../utils/helpers/common.helpers';

// Components
import InputField from '../../../components/form-elements/InputField';
import Button from '../../../components/generic/Button';
import AuthLayout from './AuthLayout';

// Images
import { useMutation } from '@tanstack/react-query';
import { signIn } from '../../../api/adapters/auth';
import { setToken } from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import { EmailIcon, LockImage, LoginImage, PasswordEyeClose, PasswordEyeOpen } from '../../assets/images';

const SignIn = () => {
    const navigate = useNavigate();

    const [inputValues, setInputValues] = useState({ email: '', password: '' });
    const [formErrors, setFormErrors] = useState({ sEmail: '', sPassword: '', });

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setUserId('123');
    //     dispatch(updateUser({ userId: '123' }));

    //     notify('success', 'You have successfully signed in!');
    //     navigate('/welcome-screen');
    // };

    const { mutate: mutateLoginUser, isLoading: isLoginLoading } = useMutation({
        mutationFn: (data) => signIn(data),
        onSuccess: (response) => {

            notify('success', response?.message);
            if (response.data.isFirstTimeLogger === true) {
                navigate('/welcome-screen');
            } else navigate('/chat-screen');
            setInputValues({ email: '', password: '' });
            setToken(response?.data?.sToken);

        },
        onError: (error) => handleApiError(error, navigate, setFormErrors)
    })

    /**
     * Sets the document title to 'Sign In'.
     */
    useEffect(() => {
        document.title = formatPageTitle('Sign In');
    }, []);

    const handleInputChange = (e) => {
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    };

    const [showPassword, setShowPassword] = useState(false);
    // Add this function to handle the click on the eye icon
    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const oData = {
            sEmail: inputValues.email,
            sPassword: inputValues.password
        }
        if (checkValidation()) mutateLoginUser(oData);
    }

    const checkValidation = () => {
        const errors = formErrors;

        if (!inputValues.email) {
            errors.sEmail = 'Email is required';
        } else {
            errors.sEmail = '';
        }
        if (!inputValues.password) {
            errors.sPassword = 'Password is required';
        } else {
            errors.sPassword = '';
        }

        setFormErrors({ ...errors });
        if (new Set(Object.values(errors)).size > 1) return false;
        else return true;
    }

    return (
        <AuthLayout
            leftImage={LoginImage}
            title='Welcome Back'
            description='Let’s get started!'
        >
            <form className=''>
                <InputField
                    label='Email'
                    type='email'
                    name='email'
                    placeholder='Enter Email Id'
                    value={inputValues?.email}
                    onBlur={
                        formErrors.sEmail
                            ? checkValidation
                            : null
                    }
                    onChange={handleInputChange}
                    icon={EmailIcon}
                    errorMessage={formErrors.sEmail}

                />
                <div className=' relative'>
                    <InputField
                        label='Password'
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        value={inputValues?.password}
                        placeholder='Enter password'
                        onChange={handleInputChange}
                        onBlur={
                            formErrors.sPassword
                                ? checkValidation
                                : null
                        }
                        icon={LockImage}
                        errorMessage={formErrors.sPassword}

                    >
                        <div className=" absolute right-4 top-1/2 -translate-y-1/2">
                            <div onClick={toggleShowPassword} className=' focus:outline-none'>
                                <img src={showPassword ? PasswordEyeClose : PasswordEyeOpen} alt="" className=' w-6 aspect-square' />
                            </div>
                        </div>
                    </InputField>
                </div>
                <Link className='text-tiny mt-2.5 text-right flex justify-end' to='/forgot-password' >Forgot password?</Link>

                <Button
                    btnType='submit'
                    type='submit'
                    className='w-full mt-10'
                    onClick={handleSubmit}
                    label='Log In'
                    size='large'
                    loader={isLoginLoading}
                />
                <div className='flex items-center justify-center gap-2'>
                    <div className=" w-28 h-0.5 bg-gray-300"></div>
                    <p className='text-small text-center my-8'>Or</p>
                    <div className=" w-28 h-0.5 bg-gray-300"></div>
                </div>
                <p className='text-small text-center text-black font-medium group'>Don’t have an account?<Link to="/sign-up" className=' text-primary group-hover:underline ml-1'>Sign up</Link> </p>
            </form>
        </AuthLayout>
    );
};

export default SignIn;
