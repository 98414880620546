import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthFooter from '../components/footer/AuthFooter';
import AuthHeader from '../components/header/AuthHeader';
import { getUserId } from '../utils/helpers/cookies.helpers';

/**
 * @component
 * A wrapper component that protects a route by checking if the user is authenticated.
 * If the user is authenticated, it renders the component passed as a prop.
 * If the user is not authenticated, it redirects to the sign-in page.
 *
 * @param {Object} props - The component props.
 * @param {React.Component} props.component - The component to be rendered if the user is authenticated.
 * @returns {React.Component} - The protected component.
 *
 * @example
 * Usage
 * <Route path='/dashboard' element={<ProtectedComponentWrapper component={<Dashboard />} />} />
 */
const ProtectedComponentWrapper = ({ component }) => {

    return getUserId() ?
        <div className=' h-lvh flex flex-col justify-between gap-3.5 bg-gray-80'>
            <AuthHeader />
            <div className=' flex-1 overflow-y-auto mx-3.5 sm:mx-4'>
                {component}
            </div>
            <AuthFooter />
        </div >
        : <Navigate to='/sign-in' replace />;
};

ProtectedComponentWrapper.propTypes = {
    component: PropTypes.element.isRequired,
};

export default ProtectedComponentWrapper;
