import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import SomethingWentWrong from '../jsx/pages/errors/SomethingWentWrong';
import store from '../redux/store';
import ErrorBoundary from './ErrorBoundary';


const ProvidersWrapper = ({ children }) => {
    return (
        <ErrorBoundary fallback={<SomethingWentWrong />}>
            <BrowserRouter>
                <Provider store={store}>
                    {children}
                    <ToastContainer />
                </Provider>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

ProvidersWrapper.propTypes = {
    children: PropTypes.element.isRequired,
};

export default ProvidersWrapper;
