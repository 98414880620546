import React from 'react';

const CommonBanner = (props) => {
    const { bannerHeading, bannerText } = props;

    return (
        // Common Banner
        <div className='common-banner'>
            <div className='inner-banner bg-home-banner bg-no-repeat bg-cover bg-center banner-top-space banner-bottom-space rounded-b-2.5xl'>
                <div className='container-small'>
                    <div className='max-w-3xl mx-auto'>
                        <h1 className='text-center heading-2 text-primary'>
                            {bannerHeading}
                        </h1>
                        <p className=' p-large text-center mt-4 text-gray'>
                            {bannerText}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommonBanner;
