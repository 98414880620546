import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { formatPageTitle, handleApiError } from '../../../utils/helpers/common.helpers';

// Componetns
import { useMutation } from '@tanstack/react-query';
import CheckedField from '../../../components/form-elements/CheckedField';
import InputField from '../../../components/form-elements/InputField';
import Button from '../../../components/generic/Button';
import AuthLayout from './AuthLayout';


// Images
import { signUp } from '../../../api/adapters/auth';
import { notify } from '../../../utils/helpers/notification.helpers';
import { EmailIcon, LockImage, PasswordEyeClose, PasswordEyeOpen, SignUpBg, UserLogo } from '../../assets/images';


const SignUp = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputValues, setInputValues] = useState({ email: '', password: '', userName: '', confirmPassword: '', });
    const [formErrors, setFormErrors] = useState({ email: '', password: '', userName: '', confirmPassword: '', });
    const [isChecked, setIsChecked] = useState(false);
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setUserId('123');
    //     dispatch(updateUser({ userId: '123' }));

    //     notify('success', 'You have successfully signed in!');
    //     navigate('/');
    // };

    const { mutate: mutateRegisterUser, isLoading: isRegisterLoading } = useMutation({
        mutationFn: (data) => signUp(data),
        onSuccess: (response) => { notify('success', response.message); navigate('/sign-in') },
        onError: (error) => handleApiError(error, navigate, setFormErrors)
    })

    /**
     * Sets the document title to 'Sign In'.
     */
    useEffect(() => {
        document.title = formatPageTitle('Sign Up');
    }, []);

    const handleInputChange = (e) => {
        setInputValues({
            ...inputValues, [e.target.name]: e.target.value
        });

    };

    const [showPassword, setShowPassword] = useState(false);
    const [showReEnterPassword, setShowReEnterPassword] = useState(false);

    // Add this function to handle the click on the eye icon
    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };
    const toggleShowReEnterPassword = (e) => {
        e.preventDefault();
        setShowReEnterPassword(!showReEnterPassword);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();


        const oData = {
            oName: inputValues.userName,
            sEmail: inputValues.email,
            sPassword: inputValues.password,
            sConfirmPassword: inputValues.confirmPassword
        }
        if (checkValidation() && isChecked) mutateRegisterUser(oData);

    }

    const checkValidation = () => {
        const errors = formErrors;

        if (!inputValues.userName) {
            errors.userName = 'Please enter your name';
        } else {
            errors.userName = '';
        }

        if (!inputValues.email.trim()) { errors.email = 'Please enter your email'; } else { errors.email = ''; }
        if (!inputValues.password.trim()) { errors.password = 'Please enter your password'; } else { errors.password = ''; }
        if (!inputValues.confirmPassword.trim()) { errors.confirmPassword = 'Please re-enter your password'; } else { errors.confirmPassword = ''; }

        // Check if all error messages are empty
        const allErrorsEmpty = Object.values(errors).every(error => error === '');

        if (allErrorsEmpty && !isChecked) {
            notify('error', 'Please accept the terms and conditions');
        }

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) return false;
        else return true;

    }

    return (
        <AuthLayout
            leftImage={SignUpBg}
            title='Start With Us'
            description='Welcome! Let’s get started!'
        >
            <form className=''>
                <InputField
                    label='Name'
                    type='text'
                    name='userName'
                    placeholder='Enter Name'
                    value={inputValues.userName}
                    onBlur={formErrors.userName ? checkValidation : null}
                    onChange={handleInputChange}
                    icon={UserLogo}
                    errorMessage={formErrors.userName}
                />
                <InputField
                    label='Email'
                    type='email'
                    name='email'
                    placeholder='Enter Email Id'
                    value={inputValues.email}
                    onBlur={formErrors.email ? checkValidation : null}
                    onChange={handleInputChange}
                    icon={EmailIcon}
                    errorMessage={formErrors.email}
                />

                <div className=' relative'>
                    <InputField
                        label='Password'
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        value={inputValues.password}
                        placeholder='Enter password'
                        onBlur={formErrors.password ? checkValidation : null}
                        onChange={handleInputChange}
                        icon={LockImage}
                        errorMessage={formErrors.password}

                    >
                        <div className=" absolute right-4 top-1/2 -translate-y-1/2">
                            <button onClick={toggleShowPassword} className=' focus:outline-none'>
                                <img src={showPassword ? PasswordEyeClose : PasswordEyeOpen} alt="" className=' w-6 aspect-square' />
                            </button>
                        </div>
                    </InputField>
                </div>

                <div className=' relative'>
                    <InputField
                        label='Confirm Password'
                        type={showReEnterPassword ? 'text' : 'password'}
                        name='confirmPassword'
                        value={inputValues.confirmPassword}
                        placeholder='Re-Enter password'
                        onBlur={formErrors.confirmPassword ? checkValidation : null}
                        onChange={handleInputChange}
                        icon={LockImage}
                        errorMessage={formErrors.confirmPassword}
                    >
                        <div className=" absolute right-4 top-1/2 -translate-y-1/2">
                            <button onClick={toggleShowReEnterPassword} className=' focus:outline-none'>
                                <img src={showReEnterPassword ? PasswordEyeClose : PasswordEyeOpen} alt="" className=' w-6 aspect-square' />
                            </button>
                        </div>
                    </InputField>
                </div>

                {/* Check Field */}
                <CheckedField checked={isChecked} onChange={handleCheckboxChange} />
                {/*  */}

                <Button
                    type='submit'
                    className='w-full mt-10'
                    onClick={handleSubmit}
                    label='Sign Up'
                    size='large'
                    loader={isRegisterLoading}
                />

                <div className='flex items-center justify-center gap-2'>
                    <div className=" w-28 h-0.5 bg-gray-300"></div>
                    <p className='text-small text-center my-8'>Or</p>
                    <div className=" w-28 h-0.5 bg-gray-300"></div>
                </div>
                <p className='text-small text-center text-black font-medium group'>Already have an account?<Link to="/sign-in" className=' text-primary group-hover:underline duration-150 ml-1'>Log In</Link> </p>
            </form>
        </AuthLayout>
    );
};

export default SignUp;

