import React from 'react';

// Images...
import { GoldenRating, NoRating } from '../../jsx/assets/images/';

const TestimonyCard = (props) => {
    const { userImage, userName, userFeedback, userRating, userDesignation } =
        props;

    // Render stars based on user rating
    const renderStars = (rating) => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <img
                    key={i}
                    src={i <= rating ? GoldenRating : NoRating}
                    alt='star'
                    className='w-4 h-4'
                />
            );
        }
        return stars;
    };

    return (
        // Card
        <div className='w-full rounded-2.7xl border-gray-50 p-7 bg-white mb-6 border inline-block align-top lg:p-6 md:p-5 md:last:mb-0'>
            <p className='p-medium leading-8 sm:leading-relaxed'>
                {userFeedback}
            </p>
            <div className='flex my-4 gap-1'>{renderStars(userRating)}</div>
            <div className='flex gap-2'>
                <div>
                    <img
                        src={userImage}
                        alt=''
                        className=' w-8 aspect-square rounded-full mt-1'
                    />
                </div>
                <div>
                    <p className='p-large text-secondary font-medium leading-7'>
                        {userName}
                    </p>
                    <p className='p-small'>{userDesignation}</p>
                </div>
            </div>
        </div>
    );
};

export default TestimonyCard;
