import React from 'react';
// Images...
import { Link } from 'react-router-dom';
import {
    FacebookIcon,
    FooterAfter,
    FooterBefore,
    FooterLogo,
    InstagramIcon,
    LinkedInIcon,
    TwitterIcon,
} from '../../jsx/assets/images';

const Footer = () => {
    // Contact data...
    const contactData = [
        {
            contactIcon: FacebookIcon,
            contactLink: 'https://facebook.com',
            contactAlt: 'Facebook',
        },
        {
            contactIcon: InstagramIcon,
            contactLink: 'https://instagram.com',
            contactAlt: 'Instagram',
        },
        {
            contactIcon: TwitterIcon,
            contactLink: 'https://twitter.com',
            contactAlt: 'Twitter',
        },
        {
            contactIcon: LinkedInIcon,
            contactLink: 'https://linkedin.com',
            contactAlt: 'LinkedIn',
        },
    ];
    // Footer Pages...
    const footerPages = [
        {
            pageLink: '/who-we-are',
            pageName: 'About us',
        },
        {
            pageLink: '/privacy-policy',
            pageName: 'Privacy Policy',
        },
        {
            pageLink: '/terms-and-conditions',
            pageName: 'Terms & Conditions',
        },
    ];
    return (
        <footer
            className='bg-secondary z-0 section-spacing-top rounded-xl mb-3.5 relative  before:bg-[image:var(--before-url)] before:bg-no-repeat before:bg-center before:bg-cover before:absolute  before:left-0 before:top-0 before:w-[30%] before:h-full before:block
            after:bg-[image:var(--after-url)] after:bg-no-repeat after:bg-center after:bg-cover after:absolute  after:right-0 after:top-0 after:w-[20%] after:h-full after:block after:-z-1 before:-z-1'
            style={{
                '--before-url': `url(${FooterBefore})`,
                '--after-url': `url(${FooterAfter})`,
            }}
        >
            <div className='container'>
                <div className='flex flex-col items-center py-16 sm:py-12'>
                    <div>
                        <img src={FooterLogo} alt='FooterLogo' />
                    </div>
                    <div className='flex items-center gap-7 text-white mt-6 mb-8 sm:gap-5 sm:flex-wrap sm:justify-center sm:text-center'>
                        {footerPages.map((page, index) => (
                            <Link
                                to={page.pageLink}
                                key={index}
                                className='text-sm text-white hover:text-primary transition-all duration-300'
                            >
                                {page.pageName}
                            </Link>
                        ))}
                    </div>
                    <div className='flex items-center gap-4'>
                        {contactData.map((contact, index) => (
                            <a
                                href={contact.contactLink}
                                key={index}
                                target='_blank'
                            >
                                <div className='w-12 bg-secondary-light duration-300 aspect-square rounded-full flex items-center justify-center hover:bg-primary'>
                                    <img
                                        src={contact.contactIcon}
                                        alt={contact.contactAlt}
                                    />
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
                <p className='text-xs text-center pb-4 text-gray-60'>
                    © 2024 Ledger Logic AI. All Rights Reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
