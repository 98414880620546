import React from 'react';
import { Link } from 'react-router-dom';

// Images
import { BackArrow } from '../../assets/images';

const ProfileLayout = (props) => {

    const { profileHeader, backTo, children } = props

    return (
        <div className='relative max-w-2xl bg-white mx-auto px-24 pt-12 pb-24 sm:px-10  xs:px-3.5 xs:pb-3.5'>
            <Link
                className="absolute top-7 left-7 flex items-center gap-1.5 group sm:top-4 sm:left-4 "
                to={backTo}
            >
                <img src={BackArrow} alt="" className=' group-hover:-translate-x-2 duration-300' />
                <p className='p-small text-black font-medium'>Back</p>
            </Link>
            <div>
                <div className="heading-5 font-medium text-black text-center mb-7 ">
                    {profileHeader}
                </div>
                {children}
            </div>
        </div>
    );
};

export default ProfileLayout;
