import React, { useEffect, useState } from 'react';

// Images
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { sendFeedback } from '../../api/adapters/chat';
import {
    BotImage,
    GoldenRating,
    NoRating,
    UserDp,
} from '../../jsx/assets/images';
import { handleApiError } from '../../utils/helpers/common.helpers';
import { notify } from '../../utils/helpers/notification.helpers';
import Modal from '../modal/Modal';

const ChatBox = (props) => {
    const { chatContainerRef, setMessages, isLoadingThread, setFeedbackSend, messages, threadId, setChatMessageQuery, chatMessageQuery } = props;

    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});


    const [scrollPosition, setScrollPosition] = useState(0);

    // Messages...
    // const [messages, setMessages] = useState([
    //     { sender: 'User', content: 'Hello' },
    //     // { sender: 'Bot', content: answers },
    //     { sender: 'Bot', content: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al' },
    //     { sender: 'User', content: 'What is the weather like today?' },
    //     { sender: 'Bot', content: 'I am sorry, I am not currently able to provide weather updates.' },
    //     // { sender: 'Error', content: 'Sorry! Your message was not understood. Please rewrite your message and start the chat again.' },
    //     // { sender: 'Typing', }

    // ]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [rating, setRating] = useState(0);
    const [historyId, setHistoryId] = useState('');


    const { mutate: mutateFeedback, isLoading: isLoadingFeedback } =
        useMutation({
            mutationFn: (data) => sendFeedback(data),
            onSuccess: (response) => {
                notify('success', response.message);
                setIsModalOpen(false);
                setRating(0);
                setFeedbackMessage('');

                setFeedbackSend(response?.data?.isFeedbackAdded);
                if (response?.data?.isFeedbackAdded) {
                    setMessages((prevMessages) =>
                        prevMessages.map((msg) =>
                            msg.iHistory === historyId ? { ...msg, is_feedback_exists: true } : msg
                        )
                    );
                }

            },
            onError: (error) => handleApiError(error, navigate, setFormErrors),
        });


    const handleInputChange = (e) => {
        setFeedbackMessage(e.target.value);
    };
    const handleSubmit = () => {
        const oData = {
            sFeedbackMessage: feedbackMessage,
            iHistory: historyId,
            nRating: rating,
        };
        if (checkValidation())
            // if (feedbackMessage.trim() !== '' && rating !== 0)
            mutateFeedback(oData);
    };

    const checkValidation = () => {
        const errors = formErrors;

        if (rating <= 0) {
            errors.nRating = 'Rating is required';
        } else {
            errors.nRating = '';
        }

        setFormErrors({ ...errors });
        const hasErrors = Object.values(errors).some(error => error !== '');

        return !hasErrors;

    }
    // const [page, setPage] = useState(1);


    useEffect(() => {

        const chatContainer = chatContainerRef.current;

        const handleScroll = () => {

            if (chatContainer) {
                const { scrollTop, scrollHeight, clientHeight } = chatContainer;
                // const maxScrollTop = scrollHeight - clientHeight;
                const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
                // let scrollPercentage = ((maxScrollTop - scrollTop) / maxScrollTop) * 100;


                if ((scrollPercentage === 0)) {
                    // setPage(page + 1);
                    // chatContainer.removeEventListener('scroll', handleScroll);
                    setScrollPosition(chatContainerRef.current.scrollHeight - chatContainerRef.current.scrollTop);
                    chatContainer.scrollTop = 0;
                    setChatMessageQuery((prevQuery) => ({
                        ...prevQuery,
                        page: chatMessageQuery.page + 1,
                    }));

                    // setScrollPercentageThreshold(scrollPercentageThreshold - 20);

                }

            }
        };


        if (chatContainer) {
            chatContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [chatMessageQuery, isLoadingThread]);




    useEffect(() => {
        if (chatContainerRef.current) {
            // chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - scrollPosition;
            // chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - (chatContainerRef.current.scrollHeight - chatContainerRef.current.scrollTop);
        }


    }, [messages])



    return (
        <div ref={chatContainerRef} className=' bg-white h-full pt-5 px-10 pb-11 overflow-y-auto relative sm:p-3.5 sm:pb-5 flex flex-col'>
            {/* <div className=' text-tiny  text-gray-60 py-2 px-5 border flex items-center justify-center  max-w-fit mx-auto rounded-3xl '>
                Today
            </div> */}

            <div className='flex flex-col gap-5 mt-14 sm:mt-6 sm:gap-6 flex-1 justify-end'>
                {messages.map((message, index) => {


                    if (threadId === messages[0].thread_id) {


                        switch (message.sender) {
                            case 'User':
                                return (
                                    <div className='flex items-end gap-2 justify-end'>
                                        <div
                                            key={index}
                                            className='max-w-xl bg-secondary p-3 rounded-xl rounded-br-none text-white self-end p-small leading-6'
                                        >
                                            {message.content}
                                        </div>
                                        <div>
                                            <img
                                                src={
                                                    user?.profilePicUrl ||
                                                    UserDp
                                                }
                                                alt=''
                                                className=' w-7 aspect-square rounded-lg'
                                            />
                                        </div>
                                    </div>
                                );
                            case 'Bot':
                                return (
                                    <div
                                        key={index}
                                        className='flex gap-2 max-w-xl items-end'
                                    >
                                        <div>
                                            <img
                                                src={BotImage}
                                                alt=''
                                                className='w-7 aspect-square'
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <div className='bg-gray-40 p-3 rounded-xl rounded-bl-none text-black p-small leading-6 tracking'>
                                                {/* {message.content} */}

                                                {message.content.split('\n').map((line, i) => (
                                                    <React.Fragment key={i}>
                                                        {line.startsWith('- ') ? (
                                                            <>
                                                                {i > 0 && <br />}
                                                                <ul>
                                                                    <li>
                                                                        {line.slice(2).split('**').map((text, j) => (
                                                                            j % 2 === 1 ? <b key={j}>{text}</b> : text
                                                                        ))}
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        ) : line.startsWith("**Reference Link**: ") ? (
                                                            <>
                                                                <b>Reference Link: </b> <Link to={line.split("**Reference Link**: ")[1]} target="_blank" style={{ color: 'blue' }}>{line.split("**Reference Link**: ")[1]}</Link>
                                                                <br />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {line.split('**').map((text, j) => (
                                                                    j % 2 === 1 ? <b key={j}>{text}</b> : text
                                                                ))}
                                                                {i < message.content.split('\n').length - 1 && <br />}
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}



                                            </div>
                                            <div className='text-micro'>
                                                {!message.is_feedback_exists ? <button
                                                    onClick={() => {
                                                        setIsModalOpen(true);
                                                        setHistoryId(
                                                            message?.iHistory
                                                        );
                                                    }}
                                                >
                                                    Give Feedback
                                                </button> : <button
                                                    onClick={() => {

                                                        notify('success', 'Feedback already given')
                                                    }}
                                                >
                                                    Feedback Accepted
                                                </button>}
                                            </div>

                                        </div>
                                    </div>
                                );
                            case 'Error':
                                return (
                                    <div
                                        key={index}
                                        className='flex gap-2 max-w-xl items-end'
                                    >
                                        <div>
                                            <img
                                                src={BotImage}
                                                alt=''
                                                className='w-7 aspect-square'
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <div className=' bg-error-bg p-3 rounded-xl rounded-bl-none  text-error p-small leading-6 tracking'>
                                                {message.content}
                                            </div>
                                        </div>
                                    </div>
                                );
                            case 'Typing':
                                return (
                                    <div
                                        key={index}
                                        className='flex gap-2 max-w-xl items-end'
                                    >
                                        <div>
                                            <img
                                                src={BotImage}
                                                alt=''
                                                className='w-7 aspect-square'
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <div className='bg-gray-40 p-3 rounded-xl inline-block rounded-bl-none'>
                                                <div className='flex gap-1 h-2 w-5 items-center'>
                                                    <div
                                                        className='h-1 w-1 bg-gray rounded-full animate-bounce'
                                                        style={{
                                                            animationDelay:
                                                                '0.2s',
                                                        }}
                                                    ></div>
                                                    <div
                                                        className='h-1 w-1 bg-gray rounded-full animate-bounce'
                                                        style={{
                                                            animationDelay:
                                                                '0.4s',
                                                        }}
                                                    ></div>
                                                    <div
                                                        className='h-1 w-1 bg-gray rounded-full animate-bounce'
                                                        style={{
                                                            animationDelay:
                                                                '0.6s',
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                return null;
                        }
                    } else {
                        switch (message.sender) {
                            case 'User':
                                return (
                                    <div className='flex items-end gap-2 justify-end'>
                                        <div
                                            key={index}
                                            className='max-w-xl bg-secondary p-3 rounded-xl rounded-br-none text-white self-end p-small leading-6'
                                        >
                                            {message.content}
                                        </div>
                                        <div>
                                            <img
                                                src={
                                                    user?.profilePicUrl ||
                                                    UserDp
                                                }
                                                alt=''
                                                className=' w-7 aspect-square rounded-lg'
                                            />
                                        </div>
                                    </div>
                                );
                            case 'Bot':
                                return (
                                    <div
                                        key={index}
                                        className='flex gap-2 max-w-xl items-end'
                                    >
                                        <div>
                                            <img
                                                src={BotImage}
                                                alt=''
                                                className='w-7 aspect-square'
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <div className='bg-gray-40 p-3 rounded-xl rounded-bl-none text-black p-small leading-6 tracking'>
                                                {message.content.split('\n').map((line, i) => (
                                                    <React.Fragment key={i}>
                                                        {line.startsWith('- ') ? (
                                                            <>
                                                                {i > 0 && <br />}
                                                                <ul>
                                                                    <li>
                                                                        {line.slice(2).split('**').map((text, j) => (
                                                                            j % 2 === 1 ? <b key={j}>{text}</b> : text
                                                                        ))}
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        ) : line.startsWith("**Reference Link**: ") ? (
                                                            <>
                                                                <b>Reference Link: </b> <Link to={line.split("**Reference Link**: ")[1]} target="_blank" style={{ color: 'blue' }}>{line.split("**Reference Link**: ")[1]}</Link>
                                                                <br />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {line.split('**').map((text, j) => (
                                                                    j % 2 === 1 ? <b key={j}>{text}</b> : text
                                                                ))}
                                                                {i < message.content.split('\n').length - 1 && <br />}
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div className='text-micro'>
                                                {!message.is_feedback_exists ? <button
                                                    onClick={() => {
                                                        setIsModalOpen(true);
                                                        setHistoryId(
                                                            message?.iHistory
                                                        );
                                                    }}
                                                >
                                                    Give Feedback
                                                </button> : <button
                                                    onClick={() => {

                                                        notify('success', 'Feedback already given')
                                                    }}
                                                >
                                                    Feedback Accepted
                                                </button>}
                                            </div>

                                        </div>
                                    </div>
                                );
                            case 'Error':
                                return (
                                    <div
                                        key={index}
                                        className='flex gap-2 max-w-xl items-end'
                                    >
                                        <div>
                                            <img
                                                src={BotImage}
                                                alt=''
                                                className='w-7 aspect-square'
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <div className=' bg-error-bg p-3 rounded-xl rounded-bl-none  text-error p-small leading-6 tracking'>
                                                {message.content}
                                            </div>
                                        </div>
                                    </div>
                                );
                            case 'Typing':
                                return (
                                    <div
                                        key={index}
                                        className='flex gap-2 max-w-xl items-end'
                                    >
                                        <div>
                                            <img
                                                src={BotImage}
                                                alt=''
                                                className='w-7 aspect-square'
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <div className='bg-gray-40 p-3 rounded-xl inline-block rounded-bl-none'>
                                                <div className='flex gap-1 h-2 w-5 items-center'>
                                                    <div
                                                        className='h-1 w-1 bg-gray rounded-full animate-bounce'
                                                        style={{
                                                            animationDelay:
                                                                '0.2s',
                                                        }}
                                                    ></div>
                                                    <div
                                                        className='h-1 w-1 bg-gray rounded-full animate-bounce'
                                                        style={{
                                                            animationDelay:
                                                                '0.4s',
                                                        }}
                                                    ></div>
                                                    <div
                                                        className='h-1 w-1 bg-gray rounded-full animate-bounce'
                                                        style={{
                                                            animationDelay:
                                                                '0.6s',
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                return null;
                        }
                    }
                })}
            </div>
            <Modal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                setRating={setRating}
                // setIsOpen={setIsModalOpen(false); setRating(0); setFeedbackMessage(''); }
                modalClass=' p-5'
            >
                <p className='p-large text-primary font-medium -mt-5'>
                    Give your feedback
                </p>
                <p className='text-tiny font-medium mt-4 mb-2.5'>
                    Rate your experience<span style={{ color: 'red' }}>*</span>
                </p>
                <div className='flex gap-1.5 justify-center max-w-44 mx-auto'>
                    {[...Array(5)].map(
                        (_, i) => (
                            <img
                                key={i}
                                src={
                                    i < rating
                                        ? GoldenRating
                                        : NoRating
                                }
                                alt=''
                                onClick={() => {
                                    setRating(
                                        i + 1
                                    );
                                    setFormErrors({ ...formErrors, nRating: '' });
                                }
                                }
                            />
                        )
                    )}
                </div>
                {formErrors && (
                    <label className='text-danger small' style={{ color: '#f21c1c' }}>{formErrors?.nRating}</label>
                )}
                <div className='mt-7 w-72'>
                    {/* <form action=""> */}
                    <label htmlFor='feedback-message'>
                        <p className='p-small text-gray-60 text-left mb-3'>
                            Feedback message
                        </p>
                    </label>
                    <textarea
                        name=''
                        id='feedback-message'
                        placeholder='Write message here '
                        className=' border border-gray-50 rounded-lg py-2.5 px-3.5 w-full min-h-28'
                        onClick={(e) =>
                            e.stopPropagation()
                        }
                        onBlur={(e) =>
                            e.stopPropagation()
                        }
                        onFocusOut={(e) =>
                            e.stopPropagation()
                        }
                        onChange={
                            handleInputChange
                        }
                    ></textarea>

                    {/* <Button
                                                            label='Submit'
                                                            className="px-6 py-3 my-4"
                                                            onClick={() => handleSubmit(message?.iHistory)}
                                                        /> */}
                    <button
                        // label='Submit'
                        className='bg-primary rounded-lg px-6 my-4 py-3 text-white'
                        onClick={() =>
                            handleSubmit()
                        }
                    >
                        Submit
                    </button>

                    {/* </form> */}
                </div>
            </Modal>

        </div >
    );
};

export default ChatBox;
