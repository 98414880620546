/**
 * @function
 * Check if the given number is a valid UK mobile number.
 *
 * @param {string} number - Mobile number to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
const isValidUkMobileNumber = (number) => {
    const pattern = /^(\+44|0)7\d{9}$/;
    return pattern.test(number);
};

/**
 * @function
 * Check if the given number is a valid German mobile number.
 *
 * @param {string} number - Mobile number to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
const isValidGermanyMobileNumber = (number) => {
    const pattern = /^(\+49|0)[1-9]{1}[0-9]{9,10}$/;
    return pattern.test(number);
};

/**
 * @function
 * Check if the given number is a valid Spanish mobile number.
 *
 * @param {string} number - Mobile number to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
const isValidSpainMobileNumber = (number) => {
    const pattern = /^(\+34|0)[67]\d{8}$/;
    return pattern.test(number);
};

/**
 * @function
 * Validate mobile number based on country language code.
 *
 * @param {('en' | 'es' | 'de')} countryLanguage - Country language code.
 * @param {string} mobileNumber - Mobile number to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
export const isValidMobileNumber = (countryLanguage, mobileNumber) => {
    switch (countryLanguage) {
        case 'en':
            return isValidUkMobileNumber(mobileNumber);
        case 'es':
            return isValidSpainMobileNumber(mobileNumber);
        case 'de':
            return isValidGermanyMobileNumber(mobileNumber);
        default:
            return false;
    }
};

/**
 * @function
 * Check if the given name is a valid UK name.
 *
 * @param {string} name - Name to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
const isValidUkName = (name) => {
    const pattern = /^[a-zA-Z-' ]+$/;
    return pattern.test(name);
};

/**
 * @function
 * Check if the given name is a valid Spanish name.
 *
 * @param {string} name - Name to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
const isValidSpainName = (name) => {
    const pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ-]+$/;
    return pattern.test(name);
};

/**
 * @function
 * Check if the given name is a valid German name.
 *
 * @param {string} name - Name to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
const isValidGermanName = (name) => {
    const pattern = /^[a-zA-ZäöüÄÖÜß-]+$/;
    return pattern.test(name);
};

/**
 * @function
 * Validate name based on country language code.
 *
 * @param {('en' | 'es' | 'de')} countryLanguage - Country language code.
 * @param {string} name - Name to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
// export const isValidName = (countryLanguage, name) => {
//     switch (countryLanguage) {
//         case 'en':
//             return isValidUkName(name);
//         case 'es':
//             return isValidSpainName(name);
//         case 'de':
//             return isValidGermanName(name);
//         default:
//             return false;
//     }
// };

/**
 * @function
 * Validate if the provided string is a valid email format.
 *
 * @param {string} email - Email to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
export const isValidEmail = (email) => {
    const regEx = /[a-z0-9._%+-]+@[a-z0-9-]+[.]+[a-z]{2,5}$/;
    return regEx.test(email);
};

/**
 * @function
 * Validate if the password is valid
 *
 * @param {string} password - Password to be validated.
 * @returns {boolean} - True if valid, false otherwise.
 */
export const isValidPassword = (password) => {
    const regEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;
    return regEx.test(password);
};

export const isValidName = function (sName) {
    const reName = /^[a-zA-Z](( )?[a-zA-Z]+){2,24}$/;
    return reName.test(sName);
};
