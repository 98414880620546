import PropTypes from 'prop-types';
import React from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';

const MainComponentWrapper = ({ component }) => {
    return (
        <div className='px-3.5'>
            <Header />
            <div>{component}</div>
            <Footer />
        </div>
    );
};

MainComponentWrapper.propTypes = {
    component: PropTypes.element.isRequired,
};

export default MainComponentWrapper;
