import React from 'react';

const SomethingWentWrong = () => {
    return (
        <div className='container-fluid'>
            <div className='row justify-content-center align-items-center vh-100'>
                <div className='col-md-6 col-lg-6 text-center'>
                    <h1 className='text-center mb-4'>Oops!</h1>
                    <p className='text-center mb-4'>
                        Something went wrong. Please try again later.
                    </p>
                    <a href='/chat-screen' className='btn btn-primary btn-block'>
                        Go back to homepage
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SomethingWentWrong;
