import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ProvidersWrapper from './Wrappers/ProvidersWrapper';
import ProjectRoutes from './routes';

function App() {
    const queryClient = new QueryClient({
        defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 1 } },
    });

    return (
        <ProvidersWrapper>
            <QueryClientProvider client={queryClient}>
                <ProjectRoutes />
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
        </ProvidersWrapper>
    );
}

export default App;
