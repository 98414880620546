import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

// Helpers
import { notify } from '../../../utils/helpers/notification.helpers';


// Component
import InputField from '../../../components/form-elements/InputField';
import Button from '../../../components/generic/Button';
import Modal from '../../../components/modal/Modal';
import ProfileLayout from './Profilelayout';

// Images
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userEditProfile } from '../../../api/adapters/myAccount';
import { updateUser } from '../../../redux/actions/user';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { isValidName } from '../../../utils/validators/common.validators';
import { EditLight, GreenTick, LockImage, UserDp, UserLogo } from '../../assets/images';


const EditProfile = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userProfile, setUserProfile] = useState({
        name: '',
        profilePic: '',

    });
    const [selectedImage, setSelectedImage] = useState(null);

    const user = useSelector((state) => state.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formErrors, setFormErrors] = useState({});
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    let oData;



    const {
        mutate: mutateUpdateProfile,
        isLoading: isMutating,

    } = useMutation({
        mutationFn: (data) => userEditProfile(data),
        onSuccess: (response) => {
            notify('success', response?.message);
            setIsModalOpen(true);
            dispatch(updateUser({
                profilePicUrl: response?.data?.sProfilePicUrl || user?.profilePicUrl,
                userName: response?.data?.oName || user?.userName,
            }))
        },
        onError: (error) => handleApiError(error, navigate, setFormErrors),

    });



    const [errorMessage, setErrorMessage] = useState({
        sName: '',
        profilePic: '',
    });

    const checkValidation = () => {
        const errors = errorMessage;
        if (selectedFile)
            if (selectedFile?.size > 5000000) {
                errors.profilePic = 'File size should be less than 5MB';
            } else if (!allowedFileTypes.includes(selectedFile?.type)) {
                errors.profilePic = 'Only jpeg, png, jpg file type allowed';
            } else {
                errors.profilePic = '';
            }
        if (!userProfile?.name) {
            errors.sName = 'Please enter a name';
        } else if (!isValidName(userProfile.name?.trim())) {
            errors.sName = 'Please enter a valid name';
        } else {
            errors.sName = '';
        }

        setErrorMessage({ ...errors });
        let nonEmptyValues = Object.values(errors).filter(
            (value) => value !== ''
        );

        if (new Set(nonEmptyValues).size > 0) return false;
        else return true;
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            setSelectedFile(file);

        }
    };

    const handleInputChange = (e) => {
        setUserProfile((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };



    function formData() {
        oData = new FormData();
        oData.append('oName', userProfile?.name);
        oData.append('uploadfile', selectedFile);
    }

    const handleSave = (event) => {
        event.preventDefault();
        if (checkValidation()) {
            formData();
            mutateUpdateProfile(oData);
        }

    };


    useEffect(() => {
        setUserProfile({
            name: user?.userName,
            profilePic: user?.profilePicUrl,
        });
    }, [user]);

    return (
        <ProfileLayout
            profileHeader="Edit Profile"
            backTo="/profile"
        >
            <form action="" >
                <div className=' border border-gray-90 rounded-lg py-7 px-12 sm:px-8 xs:px-4'>
                    <div className=''>
                        <div className='relative w-fit mx-auto'>
                            <div className='w-28 aspect-square rounded-lg group overflow-hidden'>
                                <img src={selectedImage ? selectedImage : user?.profilePicUrl ? user?.profilePicUrl : UserDp} alt="" className=' group-hover:scale-110 duration-200 w-full h-full object-cover' />
                            </div>
                            <label htmlFor="add" className=' absolute -right-2 -bottom-1 cursor-pointer'>
                                <img src={EditLight} alt="" className='w-7 aspect-square object-cover' />
                                <input type="file" accept='image/*' onChange={handleImageChange} id='add' className=' hidden' />
                            </label>

                        </div>
                        {errorMessage.profilePic ? (
                            <p
                                className='card-text'
                                style={{ color: '#FF3E1D' }}
                            >
                                {errorMessage.profilePic}
                            </p>
                        ) : null}
                    </div>
                    <div>
                        <InputField
                            label='Name'
                            icon={UserLogo}
                            type='text'
                            name='name'
                            onChange={handleInputChange}
                            value={userProfile?.name}
                            onBlur={
                                errorMessage.sName ? checkValidation : null
                            }
                        // isDisabled="true"
                        />
                        {errorMessage.sName ? (
                            <p style={{ color: '#FF3E1D' }}>
                                {errorMessage.sName}
                            </p>
                        ) : null}
                    </div>
                </div>
                <div className='border border-gray-90 rounded-lg px-12 pt-7 pb-9 mt-5 mb-7 sm:px-8 xs:px-4'>
                    <InputField
                        label='Email Id'
                        placeholder={user?.email}
                        className=" pl-3.5 "
                        isDisabled="true"
                        type='text'
                        formGroupClass="mt-0"
                    />
                    <InputField
                        label='Password'
                        type='password'
                        isDisabled="true"
                        icon={LockImage}
                        value="************"
                        formGroupClass=" !mt-4"
                    />
                    <div className=' mt-2'>
                        <Link to="/change-password" className='text-tiny text-black font-medium flex justify-end duration-200 hover:text-primary'>
                            Change Password
                        </Link>
                    </div>
                </div>
                <div className='flex justify-end items-center gap-4'>
                    <Button
                        label='Declined'
                        className="bg-white text-black hover:text-white px-6 py-3"
                        onClick={() => navigate('/profile')}
                    />
                    <Button
                        label='Save Changes'
                        className=" px-6 py-3"
                        type="submit"
                        onClick={handleSave}
                    />
                    {isModalOpen && <Modal
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        dialogTitle="Your Changes has been done successfully!"
                        dialogImage={GreenTick}
                        showImage={true}
                        onConfirm={() => {
                            setIsModalOpen(false);
                        }}
                        buttons={
                            [
                                {
                                    label: 'Okay',
                                    onClick: () => setIsModalOpen(false),

                                },
                            ]
                        }
                    />
                    }

                </div>
            </form>

        </ProfileLayout>
    );
};

export default EditProfile;
