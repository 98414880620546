
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../../../redux/actions/user';

// Helpers
import { formatPageTitle, handleApiError } from '../../../utils/helpers/common.helpers';
import { clearCookies } from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';

// Components
import InputField from '../../../components/form-elements/InputField';
import Button from '../../../components/generic/Button';
import Modal from '../../../components/modal/Modal';
import ProfileLayout from './Profilelayout';

// Images
import { useMutation } from '@tanstack/react-query';
import { changePassword } from '../../../api/adapters/myAccount';
import { isValidPassword } from '../../../utils/validators/common.validators';
import { GreenTick, LockImage, PasswordEyeClose, PasswordEyeOpen } from '../../assets/images';

const ChangePassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputValues, setInputValues] = useState({ sOldPassword: '', sNewPassword: '', sConfirmPassword: '' });
    const [errorMessage, setErrorMessage] = useState({
        sOldPassword: '',
        sNewPassword: '',
        sConfirmPassword: '',
    });

    const checkValidation = () => {
        const errors = errorMessage;

        if (!inputValues.sOldPassword?.trim()) {
            errors.sOldPassword = 'Please enter a current password';
        } else {
            errors.sOldPassword = '';
        }

        if (!inputValues.sNewPassword?.trim()) {
            errors.sNewPassword = 'Please enter a new password';
        } else if (!isValidPassword(inputValues.sNewPassword.trim())) {
            errors.sNewPassword = 'Please enter a valid password';
        } else {
            errors.sNewPassword = '';
        }

        if (!inputValues.sConfirmPassword?.trim()) {
            errors.sConfirmPassword = 'Please enter a confirm password';
        } else if (!isValidPassword(inputValues.sConfirmPassword.trim())) {
            errors.sConfirmPassword = 'Please enter a valid password';
        } else if (
            inputValues.sConfirmPassword?.trim() !==
            inputValues.sNewPassword?.trim()
        ) {
            errors.sConfirmPassword = 'Password does not match';
        } else {
            errors.sConfirmPassword = '';
        }

        setErrorMessage({ ...errors });
        let nonEmptyValues = Object.values(errors).filter(
            (value) => value !== ''
        );
        if (new Set(nonEmptyValues).size > 0) return false;
        else return true;
    };

    const { mutate: mutateUpdatePassword, isPending: isMutatingAdmin } = useMutation({
        mutationFn: (data) => changePassword(data),
        onSuccess: (data) => {
            clearCookies();
            setIsModalOpen(true);
            dispatch(
                updateUser({
                    profilePicUrl: '',
                    email: '',
                    userName: '',
                    id: '',
                    isActive: ''
                })
            );
            notify('success', data?.message);
            navigate('/sign-in');
        },
        onError: (error) => handleApiError(error, navigate, setErrorMessage),
    });

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setUserId('123');
    //     dispatch(updateUser({ userId: '123' }));

    //     notify('success', 'You have successfully signed in!');
    //     navigate('/');
    // };

    /**
     * Sets the document title to 'Sign In'.
     */

    useEffect(() => {
        document.title = formatPageTitle('Change Password');
    }, []);

    const handleInputChange = (e) => {
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    };
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Add this function to handle the click on the eye icon
    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };
    const toggleShowConfirmPassword = (e) => {
        e.preventDefault();
        setConfirmPassword(!confirmPassword);
    }
    const toggleNewPassword = (e) => {
        e.preventDefault();
        setNewPassword(!newPassword)
    }

    // save changes...
    const handleSave = (event) => {

        event.preventDefault();
        // open the modal
        if (checkValidation()) {
            mutateUpdatePassword(inputValues);
        }
    };



    return (
        <ProfileLayout
            profileHeader="Change Password"
            backTo="/profile"
        >

            <form className=''
            //  onSubmit={handleSave}
            >
                <div className=' relative'>
                    <InputField
                        label='Old Password'
                        name='sOldPassword'
                        type={showPassword ? 'text' : 'password'}
                        value={inputValues.sOldPassword}
                        placeholder='Enter password'
                        onChange={handleInputChange}
                        onBlur={
                            inputValues?.sOldPassword
                                ? checkValidation
                                : null
                        }
                        icon={LockImage}
                        formGroupClass="mt-0"
                        errorMessage={errorMessage.sOldPassword}

                    >
                        <div className=" absolute right-4 top-1/2 -translate-y-1/2">
                            <div onClick={toggleShowPassword} className=' focus:outline-none'>
                                <img src={showPassword ? PasswordEyeClose : PasswordEyeOpen} alt="" />
                            </div>
                        </div>
                    </InputField>
                </div>
                <div className=' relative'>
                    <InputField
                        label='New Password'
                        name='sNewPassword'
                        type={newPassword ? 'text' : 'password'}
                        value={inputValues.sNewPassword}
                        placeholder='Enter password'
                        onChange={handleInputChange}
                        icon={LockImage}
                        formGroupClass=" !mt-4"
                        onBlur={
                            inputValues?.sNewPassword
                                ? checkValidation
                                : null
                        }
                        errorMessage={errorMessage.sNewPassword}
                    >
                        <div className=" absolute right-4 top-1/2 -translate-y-1/2">
                            <div onClick={toggleNewPassword} className=' focus:outline-none'>
                                <img src={newPassword ? PasswordEyeClose : PasswordEyeOpen} alt="" />
                            </div>
                        </div>
                    </InputField>
                </div>
                <div className=' relative'>
                    <InputField
                        label='Confirm Password'
                        name='sConfirmPassword'
                        type={confirmPassword ? 'text' : 'password'}
                        value={inputValues.sConfirmPassword}
                        placeholder='Enter password'
                        onChange={handleInputChange}
                        icon={LockImage}
                        formGroupClass="!mt-4"
                        onBlur={
                            inputValues?.sConfirmPassword
                                ? checkValidation
                                : null
                        }
                        errorMessage={errorMessage.sConfirmPassword}

                    >
                        <div className=" absolute right-4 top-1/2 -translate-y-1/2">
                            <div onClick={toggleShowConfirmPassword} className=' focus:outline-none'>
                                <img src={confirmPassword ? PasswordEyeClose : PasswordEyeOpen} alt="" />
                            </div>
                        </div>
                    </InputField>
                </div>

                <div className='flex justify-end items-center gap-4 mt-7'>
                    <Button
                        label='Declined'
                        className="bg-white text-black hover:text-white"
                    />
                    <Button
                        label='Save Changes'
                        className=""
                        type="submit"
                        onClick={handleSave}
                    />
                    {isModalOpen && <Modal
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        dialogTitle="Your password has been update successfully!"
                        dialogImage={GreenTick}
                        showImage={true}
                        onConfirm={() => {
                            setIsModalOpen(false);
                        }}
                        buttons={
                            [
                                {
                                    label: 'Okay',
                                    onClick: () => setIsModalOpen(false),
                                },
                            ]
                        }
                    />
                    }
                </div>
            </form>
        </ProfileLayout >
    );
};

export default ChangePassword;


