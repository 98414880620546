import PropTypes from 'prop-types';
import React from 'react';

const Button = (props) => {
    const { onClick, label, size, variant, icon, className, loader, btnType } = props;
    return (
        <button
            onClick={onClick}
            className={`group btn btn-primary gap-1.5 inline-flex items-center justify-center duration-300 border font-medium px-4 border-primary rounded-lg capitalize sm:py-2 ${variant === 'secondary' ? 'btn-secondary ' : ''
                } ${size === 'large' ? 'py-3 text-md' : 'text-sm py-2.5 '} ${className ? className : ''
                }`}
            disabled={loader}
            type={btnType}
        >
            {icon && (
                <img
                    src={icon}
                    alt={label}
                    className={`flex-shrink-0 ${variant === 'secondary' ? 'group-hover:invert' : ''
                        } `}
                />
            )}
            {label}
        </button>
    );
};

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    size: PropTypes.string,
    variant: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
};

export default Button;
