import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';

// Helpers
import { formatPageTitle, handleApiError } from '../../../utils/helpers/common.helpers';

// Components
import InputField from '../../../components/form-elements/InputField';
import Button from '../../../components/generic/Button';
import AuthLayout from './AuthLayout';

// Images
import { useMutation, useQuery } from '@tanstack/react-query';
import { resetPassword, verifyResetPasswordToken } from '../../../api/adapters/auth';
import Modal from '../../../components/modal/Modal';
import { notify } from '../../../utils/helpers/notification.helpers';
import { GreenTick, LockImage, PasswordEyeClose, PasswordEyeOpen, ResetPasswordBg } from '../../assets/images';

const ResetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputValues, setInputValues] = useState({ password: '', confirmPassword: '' });
    const [formErrors, setFormErrors] = useState({
        sPassword: '',
        sConfirmPassword: '',
    });

    const { token } = useParams();

    const { isPending: isVerifyingToken } = useQuery({
        queryKey: ['token-verification', token],
        queryFn: () => verifyResetPasswordToken(token).then((res) => res.data),
        throwOnError: (error) => handleApiError(error, navigate),
        retry: false,
    });

    const { mutate: mutateResetPassword, isLoading: isMutatingResetPassword } =
        useMutation({
            mutationFn: (data) => resetPassword(data, token),
            onSuccess: (response) => {
                setIsModalOpen(true);
                notify('success', response?.message);

                setInputValues({
                    password: '',
                    confirmPassword: '',
                });
                navigate('/sign-in');
            },
            onError: (error) => handleApiError(error, navigate, setFormErrors),
        });
    /**
     * Sets the document title to 'Sign In'.
     */
    useEffect(() => {
        document.title = formatPageTitle('Reset Password');
    }, []);

    const handleInputChange = (e) => {
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    };

    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    // Add this function to handle the click on the eye icon
    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };
    const toggleShowConfirmPassword = (e) => {
        e.preventDefault();
        setConfirmPassword(!confirmPassword);
    }

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();
        let oData = {
            sPassword: inputValues?.password,
            sConfirmPassword: inputValues?.confirmPassword
        }
        if (checkValidation()) mutateResetPassword(oData);
    }

    const checkValidation = () => {
        const errors = formErrors;

        if (!inputValues.password.trim()) {
            errors.sPassword = 'Please enter your password';
        } else {
            errors.sPassword = '';
        }

        if (!inputValues.confirmPassword.trim()) {
            errors.sConfirmPassword = 'Please enter your confirm password';
        } else if (inputValues.password !== inputValues.confirmPassword) {
            errors.sConfirmPassword = 'Password and confirm password do not match';
        } else {
            errors.sConfirmPassword = '';
        }


        setFormErrors({ ...errors });
        if (new Set(Object.values(errors)).size > 1) return false;
        else return true;
    }

    return (
        <div>
            <AuthLayout
                leftImage={ResetPasswordBg}
                title='Reset Password'
                description='Create a new password'
                showBackButton={true}
            >
                <form className=''>

                    <div className=' relative'>
                        <InputField
                            label='Password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            value={inputValues?.password}
                            placeholder='Enter password'
                            onBlur={formErrors.sPassword ? checkValidation : null}
                            onChange={handleInputChange}
                            icon={LockImage}
                            errorMessage={formErrors.sPassword}
                        >
                            <div className=" absolute right-4 top-1/2">
                                <button onClick={toggleShowPassword} className=' focus:outline-none'>
                                    <img src={showPassword ? PasswordEyeClose : PasswordEyeOpen} alt="" />
                                </button>
                            </div>
                        </InputField>
                    </div>
                    <div className=' relative'>
                        <InputField
                            label='Confirm Password'
                            name='confirmPassword'
                            type={confirmPassword ? 'text' : 'password'}
                            value={inputValues?.confirmPassword}
                            placeholder='Re-enter password'
                            onBlur={formErrors.sConfirmPassword ? checkValidation : null}
                            onChange={handleInputChange}
                            icon={LockImage}
                            errorMessage={formErrors.sConfirmPassword}
                        >
                            <div className=" absolute right-4 top-1/2">
                                <button onClick={toggleShowConfirmPassword} className=' focus:outline-none'>
                                    <img src={confirmPassword ? PasswordEyeClose : PasswordEyeOpen} alt="" />
                                </button>
                            </div>
                        </InputField>
                    </div>
                    <Button
                        type='submit'
                        className='w-full mt-10'
                        onClick={handleSubmit}
                        label='Submit'
                        size='large'
                        loader={isMutatingResetPassword}
                    />
                    <Modal
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        dialogImage={GreenTick}
                        dialogTitle="Your Password has been update  successfully!"
                        showImage={true}
                        buttons={[
                            {
                                label: 'Okay',
                                onClick: () => {
                                    setIsModalOpen(false);
                                },
                                className: "w-24",
                            },
                        ]}
                    />

                </form>
            </AuthLayout>
        </div>
    );
};

export default ResetPassword;
