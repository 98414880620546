import React from 'react';

// Component...
import CommonBanner from '../../../components/common-banner/CommonBanner';

const TermsAndConditions = () => {
    return (
        <div className='content-pages'>
            {/* Common Banner */}
            <CommonBanner
                bannerHeading='Terms & Conditions'
                bannerText='Introducing TaxPay AI, the cutting-edge solution revolutionizing tax management. Seamlessly navigate the complexities of taxes with our Interactive.'
            />

            {/* Terms And Conditions Content */}
            <div className='container'>
                <div className='section-spacing-top-small flex flex-col gap-4 sm:gap-2'>
                    <h6 className='heading-small'>
                        The type of personal information we collect
                    </h6>
                    <p className='p-medium leading-relaxed'>
                        This Privacy Policy will inform you about the types of
                        personal data we collect, the purposes for which we use
                        the data, the ways in which the data is handled and your
                        rights with regard to your personal data. Furthermore,
                        this Privacy Policy is intended to satisfy the
                        obligation of transparency under the EU General Data
                        Protection Regulation 2016/679 ("GDPR") and the laws
                        implementing GDPR.
                    </p>
                    <h6 className='heading-small'>
                        The type of personal information we collect
                    </h6>
                    <p className='p-medium leading-relaxed'>
                        This Privacy Policy will inform you about the types of
                        personal data we collect, the purposes for which we use
                        the data, the ways in which the data is handled and your
                        rights with regard to your personal data. Furthermore,
                        this Privacy Policy is intended to satisfy the
                        obligation of transparency under the EU General Data
                        Protection Regulation 2016/679 ("GDPR") and the laws
                        implementing GDPR.
                    </p>
                    <h6 className='heading-small'>
                        When you provide information to us through a third-party
                        application, service or Website
                    </h6>
                    <p className='p-medium leading-relaxed'>
                        We may collect, use and share Aggregated Data such as
                        statistical or demographic data for any purpose.
                        Aggregated Data is de-identified or anonymized and does
                        not constitute Personal Data for the purposes of the
                        GDPR as this data does not directly or indirectly reveal
                        your identity. If we ever combine Aggregated Data with
                        your Personal Data so that it can directly or indirectly
                        identify you, we treat the combined data as PII which
                        will only be used in accordance with this Privacy
                        Policy.
                    </p>
                    <h6 className='heading-small'>
                        Contact you and provide you with information.
                    </h6>
                    <p className='p-medium leading-relaxed'>
                        We may transfer the information described in this
                        Privacy Policy to, and process and store it in, the
                        United States and other countries, some of which may
                        have less protective data protection laws than the
                        region in which you reside. Where this is the case, we
                        will take appropriate measures to protect your personal
                        information in accordance with this Privacy Policy.
                    </p>
                    <h6 className='heading-small'>
                        As required by law or ordered by a court, regulatory, or
                        administrative agency
                    </h6>
                    <p className='p-medium leading-relaxed'>
                        To third parties when you engage in certain activities
                        through our Services that are sponsored by them, such as
                        purchasing products or services offered by a third
                        party, electing to receive information or communications
                        from a third party, or electing to participate in
                        contests, sweepstakes, games or other programs
                        sponsored, in whole or in part, by a third party. When
                        we disclose Your Information to these third parties,
                        Your Information will become subject to the information
                        use and sharing practices of the third party, and the
                        third party will not be restricted by this Privacy
                        Policy with respect to its use and further sharing of
                        Your Information;
                    </p>
                    <h6 className='heading-small'>
                        When you register for an account with our Service
                    </h6>
                    <p className='p-medium leading-relaxed'>
                        We also automatically collect information via the
                        Website through the use of various technologies,
                        including, but not limited to Cookies and Web Beacons
                        (explained below). We may collect your IP address,
                        browsing behavior and device IDs. This information is
                        used by us in order to enable us to better understand
                        how our Services are being used by visitors and allows
                        us to administer and customize the Services to improve
                        your overall experience.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
