import React from 'react';
// 
import { Link } from 'react-router-dom';

// Components
import Button from '../../../components/generic/Button';

// Images
import { ChatHero, StartNewChat } from '../../assets/images';

const WelcomeScreen = () => {
    return (
        <div className='flex flex-col items-center justify-center min-h-full bg-white overflow-y-auto py-4'>
            <div>
                <img src={ChatHero} alt="" className=' md:max-w-80 sm:max-w-72' />
            </div>
            <div className="heading-6 max-w-sm text-center mt-5 mb-14 md:max-w-80 md:mb-8 sm:mb-5">
                This chat is empty be the first one start the chat.
            </div>
            <Link to='/chat-screen'>
                <Button
                    type='submit'
                    className=' py-5 px-8 gap-2 leading-4 sm:py-4 sm:px-6'
                    // onClick={handleSubmit}
                    icon={StartNewChat}
                    label='Start New Chat'
                    size='large'
                />
            </Link>
        </div>
    );
};

export default WelcomeScreen;
