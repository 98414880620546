import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUserId } from '../utils/helpers/cookies.helpers';

/**
 * @component
 * A wrapper component that protects a route by checking if the user is authenticated.
 * If the user is authenticated, it redirects to the home page.
 * If the user is not authenticated, it renders the component passed as a prop.
 *
 * @param {Object} props - The component props.
 * @param {React.Component} props.component - The component to be rendered if the user is not authenticated.
 * @returns {React.Component} - The protected component.
 *
 * @example
 * Usage
 * <Route path='/sign-in' element={<AuthComponentWrapper component={<SignIn />} />} />
 */
const AuthComponentWrapper = ({ component }) => {

    return getUserId() ? <Navigate to='/chat-screen' replace /> : component;
};

AuthComponentWrapper.propTypes = {
    component: PropTypes.element.isRequired,
};

export default AuthComponentWrapper;
