import React from 'react';
import { Link } from 'react-router-dom';
import { LoginIcon, Logo, UserIcon } from '../../jsx/assets/images';
import Button from '../generic/Button';

const Header = () => {


    return (
        <header className='fixed top-5 left-0 w-full z-20'>
            <div className='container-small'>
                <div className='border bg-white border-gray-20 rounded-1xl px-4 py-3 flex justify-between items-center shadow-[0_4px_12px_rgba(243,246,249,1)]'>
                    <div>
                        <Link to='/'>
                            <img
                                src={Logo}
                                alt='logo'
                                className='sm:max-w-28'
                            />
                        </Link>
                    </div>
                    <div className='flex gap-3'>
                        <Link to='/sign-in'>
                            <Button
                                label='Log In'
                                variant='secondary'
                                icon={LoginIcon}
                                className='sm:text-xs sm:px-2 sm:py-1.5'
                            />
                        </Link>
                        <Link to='/sign-up'>
                            <Button
                                label='Sign Up'
                                icon={UserIcon}
                                className='sm:text-xs sm:px-2 sm:py-1.5'
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
