import React from 'react';
import { Link } from 'react-router-dom';

const AuthFooter = () => {

    const links = [
        { to: '/who-we-are', text: 'About us' },
        { to: '/privacy-policy', text: 'Privacy Policy' },
        { to: '/terms-and-conditions', text: 'Terms and Conditions' },
    ];

    return (
        <footer className='bg-white py-5 sm:py-4'>
            <div className="container">
                <div className='flex items-center justify-between sm:flex-col sm:gap-3'>
                    <div className='flex items-center gap-12 md:gap-8 sm:gap-6 xs:gap-4'>
                        {links.map((link, index) => (
                            <Link
                                key={index}
                                to={link.to}
                                className='p-small font-medium text-gray-60 hover:text-primary duration-200 sm:text-xs'
                            >
                                {link.text}
                            </Link>
                        ))}
                    </div>
                    <div>
                        <p className='p-small text-gray-60 font-light sm:text-xs'>© 2024 Ledger Logic AI. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default AuthFooter;
