import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// Images
import { BackArrow, FooterLogo, Logo } from '../../assets/images';

const AuthLayout = ({ children, leftImage, title, description, showBackButton }) => {
    return (
        <div className='sm:bg-gray-10'>
            <div className=' py-4 bg-white border-b border border-gray-20  items-center justify-center hidden sm:flex'>
                <img src={Logo} alt="" />
            </div>
            <div className='min-h-screen flex'>
                <div className='relative max-w-lg w-full min-h-screen sm:hidden lg:max-w-sm md:max-w-xs'>
                    <div className='absolute left-10 top-5 z-1'>
                        <Link to='/'>
                            <img src={FooterLogo} alt='Logo' />
                        </Link>
                    </div>
                    <img
                        src={leftImage}
                        alt='Auth Image'
                        className='absolute object-cover object-center w-full h-full'
                    />
                </div>
                <div className='auth-content flex-1 h-screen flex items-center justify-center sm:p-2 sm:items-start relative sm:h-auto '>
                    {showBackButton && (
                        <Link className=" absolute top-11 left-16 flex items-center gap-1.5 group sm:top-4 sm:left-4 " to='/sign-in'>
                            <img src={BackArrow} alt="" className=' group-hover:-translate-x-2 duration-300' />
                            <p className='text-small text-black font-medium'>Back</p>
                        </Link>
                    )}
                    <div className='p-10 max-h-full overflow-auto flex-1 sm:bg-white sm:rounded-md sm:pt-9 sm:pb-10 sm:px-5'>
                        <div className='max-w-96 mx-auto'>
                            <div className='border-b border-gray-50 mb-5'>
                                <h1 className='heading-4 text-center font-medium pb-4'>
                                    {title}
                                </h1>
                                <p className='p-medium text-center pb-5'>
                                    {description}
                                </p>
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;

AuthLayout.propTypes = {
    children: PropTypes.node.isRequired,
    leftImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
