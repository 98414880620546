import React from 'react';

const CheckedField = ({ label, checked, onChange }) => {

    return (
        <div>
            <label className='flex items-center gap-2 mt-2.5'>
                <div className='relative flex items-center'>
                    <div className='relative w-4 h-4 shrink-0'>
                        <input
                            type='checkbox'
                            className=' absolute top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer peer'
                            checked={checked}
                            onChange={onChange}
                        />
                        <span className=' inline-block  border rounded border-primary duration-300 absolute left-0 top-0 w-full h-full  peer-checked:border-primary peer-checked:bg-white'></span>
                        <span className=' border-l-2 border-b-2 border-primary  w-2 h-1.5 inline-block duration-300 absolute  left-1 top-1 -rotate-45 opacity-0 peer-checked:opacity-100'></span>
                    </div>
                </div>
                <div className='flex items-center gap-1.5'>
                    <p className='text-tiny text-gray-60'>I agrees and accept <span className='text-tiny text-black font-medium'>Terms and Conditions & Privacy Policy</span></p>
                </div>
            </label>
        </div>
    );
};

export default CheckedField;
