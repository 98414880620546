import React from 'react';

// Components...
import CommonBanner from '../../../components/common-banner/CommonBanner';
import FaqAccordion from '../../../components/faq-accordion/FaqAccordion';

// Images...
import { AboutSection } from '../../assets/images/';

const WhoWeAre = () => {
    return (
        <div>
            {/* Common Banner  */}
            <CommonBanner
                bannerHeading='Who We Are.'
                bannerText='Introducing TaxPay AI, the cutting-edge solution revolutionizing tax management. Seamlessly navigate the complexities of taxes with our Interactive.'
            />

            {/* About Banner  */}
            <div className='bg-gray-40 mt-8 rounded-2.5xl section-inner-space-big'>
                <div className='container'>
                    <div className='flex items-center gap-6 sm:flex-col'>
                        <div className=' overflow-hidden rounded-2.5xl basis-6/12'>
                            <img
                                src={AboutSection}
                                alt=''
                                className='hover:scale-125  duration-300'
                            />
                        </div>
                        <div className=' basis-6/12'>
                            <h3 className='heading-3'>
                                Cultivating Trust Through Excellence
                            </h3>
                            <div>
                                <p className='p-large mt-3 mb-8 md:mb-5'>
                                    At TaxPay AI, we're dedicated to
                                    revolutionizing the tax management
                                    experience. With a passion for innovation
                                    and a commitment to excellence, we strive to
                                    simplify the complexities of tax filing and
                                    empower individuals and businesses to
                                    navigate their financial responsibilities
                                    with confidence.
                                </p>
                                <p className='p-large'>
                                    Our team of experts brings together a wealth
                                    of knowledge in tax law, artificial
                                    intelligence, and user experience design to
                                    develop cutting-edge solutions that meet the
                                    evolving needs of our clients.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Excellence Trust Section */}
            <div className='section-inner-space-big'>
                <div className='container'>
                    <h3 className='heading-3'>
                        Cultivating Trust Through Excellence
                    </h3>
                    <p className='p-large my-lg'>
                        Driven by a customer-centric approach, we prioritize
                        transparency, integrity, and reliability in everything
                        we do. We believe in building lasting relationships with
                        our clients, earning their trust through consistent
                        delivery of superior service and support
                    </p>
                    <p className='p-large'>
                        Join us on this journey as we redefine tax management
                        for the modern age. Discover the convenience,
                        intelligence, and reliability of TaxPay AI, and
                        experience a new standard of excellence in tax
                        assistance.{' '}
                    </p>
                </div>
            </div>

            {/* Faq Section */}
            <div className=''>
                <div className='container'>
                    <FaqAccordion />
                </div>
            </div>
        </div>
    );
};

export default WhoWeAre;
