import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Button from '../../../components/generic/Button';
import ProfileLayout from './Profilelayout';

// Images
import { useSelector } from 'react-redux';
import { EditImage, UserDp } from '../../assets/images';

const MyProfile = () => {
    const user = useSelector((state) => state.user);


    return (
        <>
            <ProfileLayout profileHeader='My Profile' backTo='/chat-screen'>
                <div className='p-6 pb-11  mx-auto border-gray-90 border rounded-lg  '>
                    <div className='flex justify-between mb-12'>
                        <div className='w-28 aspect-square rounded-md group overflow-hidden'>
                            <img
                                src={user?.profilePicUrl || UserDp}
                                alt=''
                                className=' w-full object-cover group-hover:scale-125 rounded-md duration-200 w-full h-full object-cover'
                            />
                        </div>
                        <div>
                            <Link to='/edit-profile'>
                                <Button
                                    label='Edit'
                                    className=' bg-white text-black hover:text-white hover:none'
                                    icon={EditImage}
                                    variant='secondary'
                                />
                            </Link>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5'>
                        <div className='flex gap-10 items-center'>
                            <div className='p-small'>Full Name</div>
                            <div className='p-medium text-black'>{user?.userName}</div>
                        </div>
                        <div className='flex gap-10 items-center'>
                            <div className='p-small w-16'>Email Id</div>
                            <div className='p-medium text-black'>
                                {user?.email}
                            </div>
                        </div>
                        {/* <div className='flex gap-10 items-center'>
                            <div className='p-small'>Password</div>
                            <div className='p-medium text-black'>
                                *************
                            </div>
                        </div> */}
                    </div>
                </div>
            </ProfileLayout>
        </>
    );
};

export default MyProfile;
