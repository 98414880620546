/**
 * @summary Redux store configuration.
 *
 * @description This file exports the Redux store, which is created using the `configureStore` function from the `@reduxjs/toolkit` package.
 * The store is initialized with the root reducer, which is a combination of all the reducers used in the application.
 * To add a new reducer, import it in the `rootReducer` file and add it to the `combineReducers` function.
 */

import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';

const store = configureStore({ reducer: rootReducer });

export default store;
