import React from 'react';
import { ArrowIcon, HomeBannerImage } from '../../jsx/assets/images';
import Button from '../generic/Button';

const HomeBanner = () => {
    return (
        <div className='home-banner bg-home-banner bg-no-repeat bg-cover bg-bottom banner-top-space rounded-b-2.5xl'>
            <div className='container-small'>
                <div className='text-center'>
                    <Button
                        label='Accounting in easy way'
                        icon={ArrowIcon}
                        className='!rounded-full bg-secondary tracking-wider border-secondary flex-row-reverse gap-2'
                    />
                </div>
                <h1 className='text-center heading-1 mt-5'>
                    Effortless Tax Management
                    <span className='text-secondary block'>Made Smarter.</span>
                </h1>
                <div className='mt-10 shadow-[0_-4px_12px_rgba(243,246,249,0.41)]'>
                    <img src={HomeBannerImage} alt='HomeBannerImage' />
                </div>
            </div>
        </div>
    );
};

export default HomeBanner;
