import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

// Components
import Button from '../generic/Button';

// Images
import { ModalClose } from '../../jsx/assets/images';

const Modal = (props) => {

    const { setRating, isOpen, setIsOpen, dialogImage, dialogTitle, buttons = [], children, showImage, modalClass } = props
    // console.log(setIsOpen);
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={() => { setIsOpen(false); setRating(0); }}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={`inline-block max-w-md p-10 pb-5  text-center align-middle transition-all transform bg-white rounded-xl relative ${modalClass}`}>
                            {/* Modal Close */}

                            <button className=' absolute -top-1.5 -right-1.5' onClick={() => { setIsOpen(false); setRating(0) }}>
                                <img src={ModalClose} alt="" className=' w-6 aspect-square' />
                            </button>

                            {/*  */}
                            <div className='flex flex-col items-center gap-3.5 mb-5'>
                                {showImage &&
                                    <img src={dialogImage} alt="" className=' w-12 aspect-square' />
                                }
                                <Dialog.Title
                                    className=" p-large text-black max-w-52 leading-7 font-medium"
                                >
                                    {dialogTitle}
                                </Dialog.Title>
                            </div>
                            {/*  */}
                            {children}

                            <div className=" flex items-center gap-4 justify-center">
                                {
                                    buttons.map((button, index) => (
                                        <Button
                                            key={index}
                                            label={button.label}
                                            onClick={button.onClick}
                                            className={button.className}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog >
        </Transition >
    );
};

export default Modal;