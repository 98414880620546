import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Headless Ui
import { Menu, Transition } from '@headlessui/react';

// Images
import { useDispatch } from 'react-redux';
import { DropdownArrow } from '../../jsx/assets/images';
import { updateUser } from '../../redux/actions/user';

const Dropdown = (props) => {

    const { dropdowndata, defaultData, border, customImageClass, dropdownClass, dataname, dataimage } = props;
    const [selectedData, setSelectedData] = useState(defaultData);

    const dispatch = useDispatch();

    const handleItemClick = (data) => {
        setSelectedData(data);
    };

    useEffect(() => {
        dispatch(updateUser({ isCanada: selectedData.name === 'Canadian Tax' ? false : true }))
    }, [selectedData])

    return (
        <div className="dropdown relative inline-block text-left">
            <Menu>
                {({ open }) => (
                    <>
                        <Menu.Button
                            className={` 
                                px-2 py-3 flex gap-2 items-center p-medium font-medium rounded-md text-black bg-white  hover:bg-opacity-80 focus:outline-none  border-2 border-gray-50 ${border} sm:p-1.5 md:gap-1.5 sm:text-tiny sm:font-medium sm:gap-1  ${dropdownClass}`
                            }
                        >
                            {/* <img src={selectedData.img} alt={selectedData.name} className={`aspect-square ${customImageClass}`} /> */}
                            {selectedData.name}
                            <img src={DropdownArrow} alt="" className={`mr-1 sm:mr-0 ${open ? 'rotate-180 transform' : ''}`} />
                        </Menu.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white  focus:outline-none border border-gray-90 z-10">
                                <div className="p-2.5">
                                    {dropdowndata.map((data, index) => (
                                        <Menu.Item key={index}>
                                            {({ active }) => (
                                                <Link
                                                    to={data.linkTo}
                                                    className={`group flex items-center px-2.5 py-2  gap-3 hover:bg-gray-40 duration-300`}
                                                    onClick={() => {
                                                        handleItemClick(data)
                                                    }}
                                                >
                                                    {/* <img src={data.img} alt={data.name} className=" w-5 aspect-square " /> */}
                                                    <p className='p-medium text-black font-normal'>{data.name}</p>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>
        </div>
    );
};

export default Dropdown;