import React from 'react';

const PageNotFound = () => {
    return (
        <div className='container'>
            <div className='row justify-content-center align-items-center vh-100 items-center'>
                <div className='col-md-6 text-center'>
                    {/* <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='128'
                        height='128'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-alert-circle'
                    >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='12' y1='8' x2='12' y2='12'></line>
                        <line x1='12' y1='16' x2='12.01' y2='16'></line>
                    </svg> */}
                    <h1 className='heading-1'>404</h1>
                    <h1 className='mt-4'>Oops! Page not found</h1>
                    <p className='lead'>
                        The page you are looking for might have been removed,
                        had its name changed, or is temporarily unavailable.
                    </p>
                    <a href='/chat-screen' className='btn btn-primary mt-4'>
                        Go back to homepage
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
