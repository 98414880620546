// Disclosure and Transition are used to create the accordion...
import { Disclosure, Transition } from '@headlessui/react';
import React from 'react';

// Images...
import {
    AccordionClose,
    AccordionOpen,
    DotImage,
} from '../../jsx/assets/images';

const FaqAccordion = () => {
    // Questions and answers...
    const faqData = [
        {
            faqQuestion: 'Is there a free trial available?',
            faqAnswer:
                'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
        },
        {
            faqQuestion: 'Can I change my plan later?',
            faqAnswer:
                'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
        },
        {
            faqQuestion: 'What is your cancellation policy?',
            faqAnswer:
                'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
        },
        {
            faqQuestion: 'Can other info be added to an invoice?',
            faqAnswer:
                'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
        },
        {
            faqQuestion: 'How does billing work?',
            faqAnswer:
                'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
        },
        {
            faqQuestion: 'How do I change my account email?',
            faqAnswer:
                'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
        },
    ];

    return (
        <div className=''>
            <div className='flex items-center gap-2'>
                <img src={DotImage} alt='' />
                <p className='text-base text-primary font-semibold'>FAQs</p>
            </div>
            <h2 className='heading-2 heading-margin'>
                Everything you need to know
            </h2>
            <div className='w-full '>
                <div className=''>
                    {faqData.map((faq, index) => (
                        <Disclosure
                            className='py-6 first:pt-0 md:py-5 sm:py-4'
                            key={index}
                        >
                            {({ open }) => (
                                <div className='border-b border-gray-20'>
                                    <Disclosure.Button className='flex w-full justify-between items-center text-left focus:outline-none '>
                                        <span
                                            className={
                                                open
                                                    ? 'text-primary text-xl font-semibold transition-all duration-500 ease-out lg:text-lg md:text-1xl sm:text-2md'
                                                    : 'text-secondary text-1xl font-medium transition-all duration-500 ease-in lg:text-2md md:text-md'
                                            }
                                        >
                                            {faq.faqQuestion}
                                        </span>
                                        <img
                                            src={
                                                open
                                                    ? AccordionClose
                                                    : AccordionOpen
                                            }
                                            alt=''
                                            className=' w-5 aspect-square'
                                        />
                                    </Disclosure.Button>
                                    <Transition
                                        show={open}
                                        // enter='transition-all ease-in-out duration-500 delay-[200ms]'
                                        // enterFrom='opacity-0 translate-y-6'
                                        // enterTo='opacity-100 translate-y-0'
                                        // leave='transition-all ease-in-out duration-300 delay-[200ms]'
                                        // leaveFrom='opacity-100 '
                                        // leaveTo='opacity-0 '
                                        enter={`transition-all ease-in-out duration-700 `}
                                        enterFrom='opacity-0 translate-y-6'
                                        enterTo='opacity-100 translate-y-0'
                                        leave='transition-all ease-in-out duration-300'
                                        leaveFrom='opacity-100'
                                        leaveTo='opacity-0'
                                    >
                                        <Disclosure.Panel
                                            className=' p-large pt-4 md:pt-3'
                                            static
                                        >
                                            {faq.faqAnswer}
                                        </Disclosure.Panel>
                                    </Transition>
                                </div>
                            )}
                        </Disclosure>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FaqAccordion;
